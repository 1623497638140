import React, { useContext, useEffect, useState } from "react";
import './orders.css';
import PaymentStatus from "./PayStatus";
import DelivryStatus from "./DelStatus";
import { AppContext } from "../../../AppContextProvider";
import FetchData from "../../api/FetchApiData";
import Order from "./Order";

export default function Orders()
{
    const [ShowPayOrDeliv,setShowPayOrDeliv] = useState(0);
    const [SelectedOrder,setSelectedOrder] = useState({});
    const [AllOrders,setAllOrders] = useState([]);
    const [DispOrders,setDispOrders] = useState([]);
    const [AppState,setAppState] = useContext(AppContext);

    useEffect(()=>{
        const formData = new FormData();
        formData.append('target','order');
        formData.append('request','get-all-orders');
        var jresult = [];
        FetchData(formData).then((response)=>{
            if(typeof(response)==='string')
            {jresult = JSON.parse(response);}
            else
            {jresult=response;}
            jresult = jresult.map((item)=>{return JSON.parse(item);})
            setAllOrders(jresult);
            setDispOrders(jresult);
        });
    },[])

    const DisplayPayOrDeliv=()=>
    {
        switch(ShowPayOrDeliv)
        {
            case 1:
                return <div className="StatusDetails"><PaymentStatus Order={SelectedOrder} /></div>;
            case 2:
                return <div className="StatusDetails" ><DelivryStatus Order={SelectedOrder} /></div>
            default:
                return
        }
    }

    return(<div id="Orders">
        <div>
            <fieldset id="OrdersFiltres">
                <div className="champs">
                    <select className="filtercontrol" style={{width:'100%'}}>
                        <option>Les plus recents</option>
                        <option>Les deux dernieres semaines</option>
                        <option>Tout le mois</option>
                        <option>Toute l'année</option>
                        <option>Toutes les commandes</option>
                    </select>
                </div>
                <div className="champs">
                    <label>Livraison : </label>
                    <select className="filtercontrol">
                        <option>Toutes les commandes</option>
                        <option>Validation en cours</option>
                        <option>Preparation en cours</option>
                        <option>Livraison en cours</option>
                        <option>Livraison terminé</option>
                    </select>
                </div>
                <div className="champs">
                    <label>Paiements : </label>
                    <select className="filtercontrol">
                        <option>Toutes les commandes</option>
                        <option>Validation en cours</option>
                        <option>Paiement terminés</option>
                        <option>Paiement en cours</option>
                    </select>
                </div>
                <div className="champs">
                    <label>Localisation : </label>
                    <select className="filtercontrol">
                        <option>Toutes</option>
                        <option>Autour de moi</option>
                        <option>Moroni</option>
                        <option>Gnadomboeni</option>
                    </select>
                </div>
                <div className="champs">
                    <label>Numero :</label>
                    <input className="filtercontrol" type="search" placeholder="U454C5673" style={{width:'55%'}} />
                    <img id="filtresqrscan" src="/images/icons/scan/scan.jpg" style={{height:'30px',verticalAlign:'middle'}} />
                </div>
            </fieldset>
            {
                DispOrders.map((order)=>{ 
                    return(<Order order={order} key={DispOrders.indexOf(order)} />);
                })
            }
        </div>
        {DisplayPayOrDeliv()}
    </div>);
}

