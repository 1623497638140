import React, { useEffect,useContext, useState } from "react";
import { AppContext } from "../../../AppContextProvider";
import axios from "axios";
import Popup from 'reactjs-popup';
import OrdersMap from "../globalmap/OrdersMap";
import PayStatus from "./PayStatus";
import DelStatus from "./DelStatus";
import './order.css';

export default function Order({order})
{
    const [AppState,setAppState] = useContext(AppContext);
    const [ville,setVille] = useState('');
    console.log(order);

    const MapPopUp = ()=>
        (<Popup trigger={<img src="/images/icons/map/localisation.png"></img>} position={"center center"}>
            {close =>(
                <div id="maporder" style={{minHeight:AppState.screenSize.height,width:AppState.screenSize.width,backgroundColor:'rgba(255,255,255,0.7)'}}>
                    <OrdersMap Orders={[order]} close={close} />
                </div>
            )}
        </Popup>);

    const GetCityAdress = ()=>
    {
        console.log(order.Location.coords);
        axios.get('https://nominatim.openstreetmap.org/reverse',
            {
            params:{
                format:'json',
                lat:order.Location.coords.latitude,
                lon:order.Location.coords.longitude,
            }}).then((response)=>{
            if(response.data.address.ville !== undefined)
                setVille(response.data.address.ville)
            else if(response.data.address.village !== undefined)
                setVille(response.data.address.village)
            console.log(response);
        })
    }
    
    useEffect(()=>{
        //get user
        GetCityAdress();
        return(()=>{

        });
    },[]);

    const StatusTrigger=(label,isdel=false)=>
    {
        var tcolor = 'white';
        const statdate = new Date(order.date.split("T")[0]).getTime();
        const today = new Date().getTime();
        /*
        if(isdel && (today<statdate))
        {
            tcolor = 'orange';
        }
        */
        console.log(Date(order.date.split("T")[0]),today);
        return(<div className="trigger" style={{backgroundColor:tcolor}}>
            <span>{label}</span><br/>
            <span>date : {order.date.split("T")[0]}</span>
        </div>);
    }

    return(
        <fieldset className="orderblock">
            <div onClick={e=>{}} style={{display:'inline-block',verticalAlign:'top',marginBottom:'5px'}}>
                <MapPopUp /><br/>
                <span style={{verticalAlign:'middle',marginLeft:'10px'}}>{ville+', '+order.Location.name}</span>
            </div>
            <div style={{width:'fit-content',display:'inline-block'}}>
                <div style={{}}>
                    <span style={{marginRight:'20px'}}>N° : {'U234C89908'}</span>
                    <span>{AppState.user.labelname}</span>
                </div>
                <div onClick={e=>{}} style={{display:'inline-block'}}>
                    <DelStatus Order={order} trigger={StatusTrigger("Livraison",true)} />
                  
                </div>
                <div onClick={e=>{}} style={{display:'inline-block'}}>
                    <PayStatus Order={order} trigger={StatusTrigger("Paiement")} />
                </div>
            </div>
            <div onClick={e=>{}} style={{display:'inline-block',verticalAlign:'top'}}>
                <input type="button" style={{width:'70%',margin:'5px 5px 0px 5px',backgroundColor:'lightcoral',borderStyle:'none',padding:'7px 5px 5px 5px',borderRadius:'5px',fontWeight:'bold'}} value='Annuler' />
                <input type="button" value="Terminer" style={{width:'70%',margin:'5px 5px 0px 5px'}} />
            </div>
        </fieldset>
    );
}