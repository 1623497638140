import { ReactComponent as Loader } from './loader.svg';
import './buttonloader.css';

const Button = ({ onSubmit, text,style={backGroundColor:'lightgray',fontSize:'medium'}, loading = false, disabled }) => {
  return (
    <button style={{background:style.backGroundColor,fontSize:style.fontSize}} className="submit-btn" onClick={onSubmit} disabled={disabled}>
      {!loading ? text : <Loader className="spinner" />}
    </button>
  )
}

export default Button