import { useEffect, useState,useContext, useRef } from 'react';
import './filtres.css';
import MultiRangeSlider from "multi-range-slider-react";
import Button from '../../utils/ButtonLoader';
import { AppContext } from '../../../AppContextProvider';
import FetchData from '../../api/FetchApiData';

export default function Filtres({items,setToDisplay,Columns})
{
    const [minPrice,setMinPrice] = useState(0);
    const [maxPrice,setMaxPrice] = useState(100);
    const [data,setData] = useState({rayons:new Map(),caracteristiques:[]});
    const [display,setDisplay] = useState({display:'block',text:'-'});
    const [loading,setLoading] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);
    const [selrayons,setSelRayons] = useState([]);
    let selrayonsRef = useRef([]);
    const [caracteristiques,setCaracteristiques] = useState([]);
    const [Categories,setCategories] = useState([]);
    const [Fabricants,setFabricants] = useState([]);
    const [displayCategoris,setDisplayCategories] = useState('none');

    useEffect(()=>{
        
    },[selrayons])

    const findInMap = (map, val) => {
        for (let [k, v] of map) {
            //console.log(v);
          if (v.find(e=>e===val)!==undefined) { 
            return true; 
          }
        }  
        return false;
      }

    const FetchCategories = (element)=>
    {
        let categorie = JSON.parse(element.categories);
        let found = [...data.rayons.entries()].find(pair=>pair.key===element.rayon);
        if(found !== undefined)
        {
            if(found.value.categories.find(categorie.name)=== undefined)
                found.value.categories.push(categorie.name);
            if(found.value.fabricants.find(element.fabricant)=== undefined)
                found.value.fabricants.push(element.fabricant);
            data.rayons.set(element.rayon,{categories:found.value.categories,fabricants:found.value.fabricants});
        }
        else
        {
            let object  = {categories:[categorie.name],fabricants:[{name:element.fabricant,quantity:0}]}
            data.rayons.set(element.rayon,object);
        }
    }

    const FetchCaracteristiques = (element) =>
    {
        let categorie = JSON.parse(element.categories);
        let caracteristiks = categorie.caracteristiques;
        let foundcar = data.caracteristiques.find((car)=>car.categorie === categorie.name);
        //console.log(foundcar,caracteristiks);
        if(foundcar!==undefined)
        {
            let index = data.caracteristiques.indexOf(foundcar);
            caracteristiks.forEach((car)=>{
                //console.log(findInMap(foundcar.caracteristiques,car.value),car.value,foundcar.caracteristiques);
                if(foundcar.caracteristiques.has(car.name) && !findInMap(foundcar.caracteristiques,car.value))
                {
                    let values = foundcar.caracteristiques.get(car.name);
                    values.push(car.value);
                    foundcar.caracteristiques.set(car.name,values)
                }
                else if(!foundcar.caracteristiques.has(car.name))
                {
                    foundcar.caracteristiques.set(car.name,[car.value])
                }
            });
            data.caracteristiques[index] = foundcar;   
        }
        else
        {
            let caractobject = {categorie:categorie.name,caracteristiques:new Map()} 
            caracteristiks.forEach((car)=>{
                caractobject.caracteristiques.set(car.name,[car.value])
            });
            data.caracteristiques.push(caractobject);
        }   
    }


    useEffect(()=>{
        var formData = new FormData();
        formData.append('target','article');
        formData.append('request','get-filters');
        setLoading(true);
        FetchData(formData).then((response)=>
        {
            response = typeof(response) === 'string' ? JSON.parse(response) : response;
            response.forEach((element) => {
                FetchCategories(element);
                FetchCaracteristiques(element);
            });
            setData({...data});
            setLoading(false);
        });
    },[])
    

    const HandleCategorieClick=(cat,checked)=>
    {
        if(checked)
        {
            Categories.push(cat);
            setCategories([...Categories]);
        }
        else
        {
            let index = Categories.indexOf(cat);
            Categories.splice(index,1);
            setCategories([...Categories]);
        }
    }

    const CheckBox = (label,count=0,click=()=>{},img={disp:false,src:''},displaycount='none')=>
    {
        let imgjsx = '';
        if(img.disp)
            imgjsx = <img src={img.src} style={{width:'40px',height:'40px',borderRadius:'20px',verticalAlign:'middle',marginRight:'10px'}}/>;
        return(<div style={{display:'block',width:'100%',textAlign:'left',marginTop:'5px'}}>
            {imgjsx}
            <label style={{verticalAlign:'middle'}}>{label}</label>
            <span style={{color:'gray',display:displaycount}}>{' ('+count+')'}</span>
            <input onClick={(e)=>{click(label,e.target.checked)}} type='checkbox' style={{float:'right',verticalAlign:'middle'}} />
        </div>);
    }

    const CheckBoxCat = (categorie,click=()=>{},img={disp:false,src:''},displaycount='none')=>
    {
        let imgjsx = '';
        if(img.disp)
            imgjsx = <img src={img.src} style={{width:'40px',height:'40px',borderRadius:'20px',verticalAlign:'middle',marginRight:'10px'}}/>;
        return(<div style={{display:'block',width:'100%',textAlign:'left',marginTop:'5px'}}>
            {imgjsx}
            <label style={{verticalAlign:'middle'}}>{categorie.name}</label>
            <span style={{color:'gray',display:displaycount}}>{' ('+categorie.quantity+')'}</span>
            <input onClick={(e)=>{click(categorie,e.target.checked)}} type='checkbox' style={{float:'right',verticalAlign:'middle'}} />
        </div>);
    }

    const HandleDisplay=()=>
    {
        if(display.display=='none')
        {setDisplay({display:'block',text:'-'});}
        else{setDisplay({display:'none',text:'+'});}
    }

    const HandleRayonClick = (rayon,checked)=>
    {
        if(Array.from(data.rayons.keys()).findIndex(element=> rayon === element)!==-1)
        {
            if(checked)
            {
                selrayons.push(data.rayons.get(rayon));
                selrayonsRef.current.push(rayon);
                setSelRayons([...selrayons]);  
            }
            else
            {
                const index = selrayons.indexOf(data.rayons.get(rayon));
                selrayons.splice(index,1);
                selrayonsRef.current.splice(index,1);
                setSelRayons([...selrayons]);
            }
            if(selrayons.length===0 || selrayons.length>1)
                setDisplayCategories('none');
            else
                setDisplayCategories('block');
        }
        let itemstodisp = [];
        items.forEach((item)=>{
            if( selrayonsRef.current.includes(item.rayon))
            {itemstodisp.push(item);}
        });
        setToDisplay([...itemstodisp]);
    }

    return(
    <div id="FiltresBlock">
        <div id="FiltresTitle">
            <div className="BtnDispFilters"><Button text={display.text} onSubmit={e=>{HandleDisplay()}} style={{backGroundColor:'ghostwhite',fontSize:'large'}} loading={loading}/></div>
            <span>Filtres</span>
        </div>
        <div style={{display:display.display}}>
            <fieldset className='FiltresFieldset'>
                <legend>Prix</legend>
                <label >Prix Minimum <span style={{color:'gray'}}>({minPrice} Euros)</span></label>
                <input type='range' step={10} multiple={true} style={{width:'100%'}} onChange={e=>{if(e.target.value<maxPrice-20){setMinPrice(e.target.value)}}} min={0} max={10000} value={minPrice}/>
                <label >Prix Maximum <span style={{color:'gray'}}>( {maxPrice} Euros)</span></label>
                <input type='range' step={10} multiple={true} style={{width:'100%'}} onChange={e=>{if(minPrice<e.target.value-20){setMaxPrice(e.target.value)}}} min={0} max={10000} value={maxPrice}/>
            </fieldset>

            <fieldset className='FiltresFieldset'>
                <legend>Rayons</legend>
                {
                    AppState.rayons.map((rayon)=>{
                    return(CheckBox(rayon.name,rayon.quantity,HandleRayonClick,{disp:true,src:rayon.img}))})
                }
            </fieldset>

            <fieldset style={{display:displayCategoris}} className='FiltresFieldset'>
                <legend>Catégories</legend>
                {
                    selrayons.map((rayon)=>
                    {
                        return(
                        rayon.categories.map((cat)=>{
                            return(CheckBox(cat,0,HandleCategorieClick))
                        }));
                    }
                    
                )
                }
            </fieldset>

            <fieldset style={{display:displayCategoris}} className='FiltresFieldset'>
                <legend>Fabricants</legend>
                    {
                        selrayons.map((rayon)=>{
                            return (rayon.fabricants.map((f)=>{
                                return CheckBox(f.name,f.quantity)
                        }))
                    })}           
            </fieldset>
            
            {Categories.map((cat)=>{
                return(
                    <fieldset className='FiltresFieldset' style={{paddingTop:'10px'}}>
                        <legend>{cat}</legend>
                            {
                                data.caracteristiques.map((cara)=>
                                {
                                    return (Array.from(cara.caracteristiques).map(([name,values])=>{
                                        return(<div style={{textAlign:'left'}}>
                                            <label style={{fontWeight:'bold'}}>{name}</label>
                                            <hr />
                                             {values.map((val)=>{
                                                return CheckBox(val,0,()=>{})
                                             })}
                                        </div>)
                                    }));    
                                })
                            }

                    </fieldset>
                );
            })}

        </div>
    </div>);
}