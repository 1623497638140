import React from "react";
import Une from "./components/une/Une";

const initState = 
{
    page: <Une />,
    backGroundImage:"",
    user:{auth:false,ad:false,labelname:"Espace Client",panier:{articles:[],lastview:[]}},
    navBar:{BackColor:"transparent",Position:"relative",Color:"white"},  
    navBtnL:{'onClick':()=>{},'display':'none','image':"/images/icons/profile.png"},
    navBtnR:{'onClick':()=>{},'display':'none','image':"/images/icons/panier.png"},
    navBtnH:{display:'block'},
    rayons : [{name:'Photovoltaique',quantity:0,img:'/images/icons/pv.png'},{name:'Construction',quantity:0,img:'/images/icons/btp.jpg'},{name:'Beauté',quantity:0,img:'/images/icons/beaute.jpg'}],
    ShowSearchBar: false,
    phpLog: "",
    screenSize:{width:window.innerWidth,height:window.innerHeight},
    currentposition:null
};

export const AppContext = React.createContext(initState);