import React, { useEffect, useState, useContext } from "react";
import PhoneInput from 'react-phone-number-input';
import Vitrines from "../../vitrines/Vitrines";
import 'react-phone-number-input/style.css';
import { AppContext } from "../../../AppContextProvider";
import './login.css';
import Button from "../../utils/ButtonLoader";
import FetchData from "../../api/FetchApiData";
import User from "../User";

export default function Login({ConForm=true})
{
    const [ConFormIsSelected,setConFormIsSelected] = useState(ConForm);
    const [data,setData] = useState({"name":"","prenom":"","email":"","phone":"","password":"","phone":{country:'KM',number:""}});
    const [loginData,setLoginData] = useState({'email':"","password":""});
    const [charte,setCharte] = useState(false);
    const [notification,setNotification] = useState({"origine":"","value":""});
    const [charteColor,setCharteColor] = useState("black");
    const [colorEmail,setColorEmail] = useState("darkgray");
    const [colorCpass,setColorCpass] = useState("darkgray");
    const [loading,setLoading] = useState(false);
    const [log,setLog] = useState("");
    const [AppState,setAppState] = useContext(AppContext);
    
    const InitData = ()=>{
        setData({"name":"","prenom":"","email":"","phone":"","password":"","phone":{country:'KM',number:""}});
    }

    useEffect(()=>{
    if(!AppState.user.auth)
    {
        setAppState({
                ...AppState,
                ShowSearchBar:true,
                navBtnH:{display:'block'},
                navBtnH:{display:'inline-block'},
                navBtnL:
                {...AppState.navBtnL,
                    onClick:()=>{},
                    image:"/images/icons/empty.png",
                    display:'none'
                },
                navBtnR:
                {...AppState.navBtnR,
                    onClick:()=>{},
                    image:"/images/icons/panier_white.png",
                    display:'inline-block'
                },
                navBar:{BackColor:"transparent",Color:"white"},
                backGroundImage:"url(/images/icons/login.jpg)"
            });
    }
    else
    {
        setAppState({...AppState,page:<User App={AppState} />});
    }
    },[]);

    const notify=()=>
    {
        let spanColor = "";
        let spanText = "";
        let text = "";
        switch(notification['origine'])
        {
            case 'inscription':
                if(notification['value']==="fail")
                {   
                    spanColor = "orangered";
                    spanText = "Nous rencontrons des problèmes en ce moment.";
                    text = "Veuillez s'il vous plait reessayer plus tard.";
                }
                else if(notification['value']==="exist")
                {
                    spanColor = "orangered";
                    spanText = "";
                    text = "Un compte avec l'adresse que vous avez founi existe déjà. Veuillez vous connecter.";
                }
                else if(notification['value']==="success")
                {
                    spanColor = "green";
                    spanText = "Inscription reussie : ";
                    text = "Un code vous a été envoyé par mail. Veuillez suivre le lien dans le mail pour valider l'adresse email que vous avez fourni."
                }
                else if(notification['value']==="network")
                {
                    spanColor = "red";
                    spanText = "Problème d'accès à internet.";
                    text = ""; 
                }
                break;
            case 'password':
                if(notification['value']==="incorrect")
                {
                    spanColor = "red";
                    spanText = "Votre mot de passe doit contenir au moins 8 caractères, au moins un majiscule, un moins un miniscule";
                    text = "";
                }
                break;
            case 'login':
                if(notification['value']==="not-found")
                {
                    spanColor = "orangered";
                    spanText = "Compte introuvable.";
                    text = "Verifiez que votre adresse mail est correct";  
                }
                else if(notification['value']==="wrong-password")
                {
                    spanColor = "red";
                    spanText = "Mot de passe incorrect.";
                    text = ""; 
                }
                else if(notification['value']==="network")
                    {
                        spanColor = "red";
                        spanText = "Problème d'accès à internet.";
                        text = ""; 
                    }
                else
                {
                        spanColor = "";
                        spanText = "";
                        text = ""; 
                }
                break;
            case 'email':
                spanColor = "orangered";
                spanText = "Email invalide";
                text = "";
            default:
                break;    
        }
        return(
            //<h4 style={{margin:"-5px"}}>Reussie</h4>
            <p style={{color:spanColor,width:'60%',position:'relative',left:'20%'}}>
                <span style={{color:spanColor}}>{spanText}</span>
                {text}    
            </p>
        );
    }

    const validateEmail=(email)=>
    {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    const HandleData=(htmlElement)=>
    {
        if(htmlElement.target.name==='cpassword')
        {
            if(htmlElement.target.value!==data['password'])
                setColorCpass("red");
            else
                setColorCpass("darkgray");
            return;
        }
        else
        {
            data[htmlElement.target.name] = htmlElement.target.value;
        }

        if(htmlElement.target.name==='email')
        {
            if(validateEmail(htmlElement.target.value))
            {setColorEmail("red");return}
            else{setColorEmail("red");}
        }
        if(htmlElement.target.name==='password')
        {
            
        }
        //console.log(data);
    }

    const HandlePhone=(value,isNumber)=>
    {
        setNotification({"origine":"","value":""});
        if(isNumber)
        {
            data["phone"].number = value;
        }
        else{data["phone"].country = value;}
    }

    const ConnexionForm=()=>
    {
        return(
            <div className="Form">
                    <div className="inputDiv">
                        <label>Email</label>
                        <input onChange={e=>{setLoginData({...loginData,"email":e.target.value})}} type="email" name="email" placeholder="votre email" />
                    </div>
                    <div className="inputDiv">
                        <label>Mot de Passe</label>
                        <input name="password" onChange={e=>{setLoginData({...loginData,"password":e.target.value})}} type="password" placeholder="*************" />
                    </div>
                    <div className="btnSubmit"><Button loading={loading} onSubmit={e=>{LoginRequest()}} style={{backGroundColor:'yellowgreen',fontSize:'small'}}  text={"Envoyer"} /></div>
                    <input onClick={e=>{InitData();setConFormIsSelected(false);}} type="button" className="btnSubmit" style={{backgroundColor:'lightgray'}} value={"Inscription"}></input>
            </div>
        );
    }

    const LoginRequest=()=>
    {
        const formData = new FormData();
        formData.append('target','client');
        formData.append('request','client-login');
        formData.append('value',JSON.stringify(loginData));
        setLoading(true);
        FetchData(formData).then((response)=>{
            if(response!==undefined)
            {
                if(response==="wrong-password" || response==="not-found")
                {
                    setNotification({"origine":"login","value":response})
                }
                else
                {
                    AppState.user.panier.articles.forEach(element => {
                        let found = response.panier.articles.find((e) => e.reference===element.reference);
                        if(found===undefined)
                        {
                            response.panier.articles.push(element);
                        }
                    });
                    setAppState({...AppState,user:{...response,labelname:response.prenom+" "+response.name.toUpperCase(),auth:true},
                    navigationColor:"white",
                    page:<Vitrines />,
                    navBtnR:{...AppState.navBtnR,
                        'onClick':()=>
                            {
                                if(!AppState.user.authentification)
                                {
                                    setAppState({...AppState,page:<Login />});
                                }
                                else
                                {
                                    setAppState({...AppState,page:<User />});                       
                                }
                            },
                        image:"/images/icons/panier_white.png"
                    }
                });
                }
            }
            else{setNotification({"origine":"login","value":"network"})}
            setLog(response);
            setLoading(false);
        });
    }

    const RegistrationRequest=()=>
    {
        if(charte)
        {
            const formData = new FormData();
            formData.append('target','client');
            formData.append('request','client-registration');
            data['panier'] = {articles:[],statut:"notsent",delivryAdress:[],PrixTotal:"",payementHistory:[]};
            data["locations"] = [];
            data["commandes"] = [];
            data["consulte"] = [];
            data['messagerie'] = [];
            data['reference'] = "";
            formData.append('value',JSON.stringify(data));
            FetchData(formData).then((response)=>{
                if(response!==undefined)
                {
                    setNotification({"origine":"inscription","value":response})
                    setConFormIsSelected(true);
                }
                else{setNotification({"origine":"inscription","value":"network"})}
            });
            setData([]);
        }
        else
        {
            setCharteColor("red");
        }
    }

    const inscriptionForm = ()=>
    {
        return(
        <div className="Form">
            <div className="inputDiv">
                <label>Nom</label>
                <input onChange={e=>{HandleData(e)}} name="name" type="text" placeholder="Votre Nom" />
            </div>
            <div className="inputDiv">
                <label>Prenom</label>
                <input onChange={e=>{HandleData(e)}} name="prenom" type="text" placeholder="Votre Prenom" />
            </div>
            <div className="inputDiv">
                <label>Email</label>
                <input onChange={e=>{HandleData(e)}} name="email" type="email" placeholder="Votre adress email" />
            </div>
            <div className="inputDiv">
                <label>Télephone</label>
                <div id="inputTel">
                    <PhoneInput name="phone" defaultCountry={'KM'} onCountryChange={(e)=>{HandlePhone(e,false)}} placeholder="Enter phone number" onChange={(e)=>{HandlePhone(e,true)}}/>
                </div>
            </div>
            <div className="inputDiv">
                <label>Mot de passe</label>
                <input onChange={e=>{HandleData(e);setNotification({"origine":"password","value":""});}} name="password" type="password" placeholder="*************" />
            </div>
            <div className="inputDiv">
                <label>Confirmez le Mot de Passe</label>
                <input onChange={e=>{HandleData(e);}} name="cpassword" style={{border: "1px solid "+colorCpass}} type="password" placeholder="*************" />
            </div>
            <div style={{marginTop:"10px",marginBottom:"10px"}}>
                <input onClick={e=>{setCharte(e.target.checked)}} name="charte" type="checkbox" style={{marginRight:"20px",border:"1px solid "+charteColor}} /> <label style={{fontSize:'small'}}>j'accepte les <a>conditions</a> d'utilisations de ce service</label>
            </div>
            <div className="btnSubmit"><Button loading={loading} onSubmit={e=>{RegistrationRequest();}} text={"Envoyer"} style={{backGroundColor:'yellowgreen',fontSize:'small'}} /></div>
            <input onClick={e=>{setConFormIsSelected(true);}} type="button" className="btnSubmit" style={{backgroundColor:'lightgray'}} value={"Connexion"}></input>
        </div>);
    }

    const ShowForm = ()=>
    {
        if(ConFormIsSelected)
            return ConnexionForm();
        else
            return inscriptionForm();
    }

    return(
        <div id="Login">
            <fieldset style={{border:'none'}}>
                {notify()}
                {ShowForm()}
            </fieldset>
            <div>{log}</div>
        </div>
    );
}