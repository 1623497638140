import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";


export default function Avis(props)
{
    const [note, setNote] = useState(2.5);
    useEffect(()=>{setNote(props.avis.note);},[]);
    if(props.isNew)
    {
        return(<div style={{padding:'10px'}}>
                    <div>
                        <span style={{marginRight:'20px'}}>Vous :</span> 
                        <Rating initialValue={note} size={15} onClick={e=>{}} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} />
                    </div>
                    <textarea placeholder="Que pensez-vous de ce produit ?" id="textbox" style={{width:'95%',border:'1px solid lightgray',borderRadius:'5px',minHeight:'100px'}}></textarea>
                    <input type='button' value={'Envoyer'} style={{margin:'10px',padding:'5px 10px 5px 10px'}}/>
            </div>);
    }
    else
    {
        return(
        <div style={{border:'1px solid lightgray',borderRadius:'5px',padding:'10px',marginTop:"10px"}}>
            <div>
                <span style={{marginRight:'20px'}}>{props.avis.other} :</span> 
                <Rating size={15} readonly={true} initialValue={props.avis.note} onClick={e=>{}} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={(index)=>{setNote(index)}} />
            </div>
            <hr />
            <p id="textbox">{props.avis.text}</p>
        </div>);
    }
}