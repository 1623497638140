import { useState, useContext } from 'react';
import './produit.css';
import TextPargraph from '../utils/TextParagraph';
import Avis from './Avis';
import { Rating } from 'react-simple-star-rating';
import { AppContext } from '../../AppContextProvider';
import { BrowserView, MobileView } from 'react-device-detect';

export default function Produit(props)
{
    var ckit = [];
    const article = props.article;
    if(article.isKit==="Oui")
    {
        ckit = article.compoKit;
    }
    const [mainImage,setMainImage] = useState(article.images[0].replace("reference",article.reference))
    const [displaydownloads,setDisplayDownloads] = useState(false);
    const [btnDownloadsColor,setDownloadsColor] = useState("white");
    const [btnCaracteristiquesColor,setCaracteristiquesColor] = useState("lightgray");
    const [note,setNote] = useState(2.5);
    const [AppState,setAppState] = useContext(AppContext);

    const ShowElementCompo = ()=>
    {
        if(props.article.isKit==="Oui")
        {
        return(
        <div>
            <h3>Composition du produit : </h3>
            <div>
            {article.compoKit.map((art)=>{
                return(
                    <div className="CompoArticle">
                    <div className="CompoImage">
                        <img alt='image' src={art.images[0].replace("reference",art.reference)} /> 
                    </div>
                    <div className="CompotTitle">
                        <label>{art.nom}</label>
                    </div>
                    <div className="CompotQty">
                        <label style={{maxWidth:'30%',display:'inline-block'}}>Qté : </label>
                        <input type='number' style={{width:'60%',display:'inline-block'}} defaultValue={art.quantite} />
                    </div>
                </div>   
                )
            })}
            </div>
        </div>);}
        else{return}
    }

    const AddToPanier = ()=>
        {
            const found = AppState.user.panier.articles.find((element) => element.reference === article.reference);
            if(found!==undefined)
            {
                let index = AppState.user.panier.articles.indexOf(found);
                found.quantite = typeof(found.quantite)==='string' ? parseInt(found.quantite)+1 : found.quantite+1; 
                AppState.user.panier.articles[index] = found;
            }
            else{
                AppState.user.panier.articles.push({reference:article.reference,quantite:article.quantite,price:article.prix,delivry:0});
            }
            setAppState({...AppState});
            if(AppState.user.auth)
            {
                //SavePanier();
            }     
        }

    const displayDownloads=()=>
    {
        if(!displaydownloads)
        {
            return(     
                <div>
                    <hr style={{marginTop:'0px'}} />
                    {
                        article.caracteristiques.map((car)=>{
                            return(
                                <div key={article.caracteristiques.indexOf(car)} className='CarRow'>
                                    <span className='CarName' style={{paddingLeft:'20px'}}>{car.name}</span><span style={{float:'right',paddingRight:'30px'}} className='CarValue'>{car.value}</span>
                                    <hr style={{marginTop:'0px'}} />
                                </div>);
                                })
                    }
                </div>)
        }
        else{
            return(
                <div>
                    <hr style={{marginTop:'0px'}} />
                    {
                        article.documents.map((doc)=>{
                            return(
                                <div key={article.documents.indexOf(doc)} className='CarRow'>
                                    <div>
                                        <span style={{paddingLeft:'20px'}} className='CarName'>{doc.replace(/^.*[\\\/]/,'')}</span>
                                    </div>
                                    <hr style={{marginTop:'0px'}} />
                                </div>);
                                })
                    }
                </div>
            )
        }
    }
    
    const ShowNewAvis = ()=>
    {
        console.log(AppState.user.auth);
        if(AppState.user.auth)
        {
            return(<Avis isNew={true} avis={{'note':2.5}} />);
        }
    }


    return(
        <div id='Produit'>
            <MobileView>
                <div id='title'>
                    <h1 style={{fontSize:'medium'}}>{article.nom}</h1>
                    <h2 className='infosBlock' id='Reference'><span style={{marginRight:'20px'}}> Reférence : {article.reference}</span> 
                    <Rating iconsCount={5} initialValue={note} size={15} onClick={e=>{}} readonly={true}  onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} /> </h2>
                </div>
            </MobileView>
            <div id='DivImages'>
                <img id='mainImage' src={mainImage} />
                {article.images.map((image)=>
                {return <img onClick={e=>{setMainImage(image.replace("reference",article.reference))}} className='subImage' src={image.replace("reference",article.reference)} />;}
                )}
            </div>
            <div id='ProduitInfos'>
                <BrowserView>
                    <div id='title'>
                        <h1>{article.nom}</h1>
                        <h2 className='infosBlock' id='Reference'>
                            <span style={{marginRight:'50px'}}> Reférence : {article.reference} </span> 
                            <Rating size={30} onClick={e=>{}} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} /> 
                        </h2>
                    </div>
                </BrowserView>
                <div id='command'>
                    <div style={{color:'green'}} id='stock' className='infosBlock'><label>{article.stock} En stock aux Comores</label></div>
                    <div className='infosBlock'>
                        <label >Quantité : </label><input onChange={e=>{article.quantite = e.target.value;}} id='InputQuantity' defaultValue={1} step={1} type='number' />
                        <input onClick={e=>{AddToPanier();}} id='btnCommand' type='button' value={'Commander'} />
                    </div>
                    <div id='DivDelivry' className='infosBlock'>
                        <span style={{color:'#581845'}}>
                            <img src='/images/icons/delivry.png' style={{width:'30px',height:'30px',verticalAlign:'middle',marginRight:'20px'}} />
                            Livraison chez vous sous 3 jours depuis Moroni.
                        </span>
                    </div>
                </div>
                <div id='paiement' className='infosBlock'>
                    <div id='price'>
                        <span style={{fontWeight:'bold'}}> Prix/unité : </span>
                        <hr/>
                        <div style={{display:'inline-block',color:'blue'}}>
                            <h2 style={{marginBottom:'3px',marginTop:'0px',fontSize:'large'}}>{article.prix} €</h2>
                            <span>Hors Livraison</span>
                        </div>
                        <div style={{color:'darkgray',display:'inline-block',float:'right'}}>
                            <h2 style={{marginBottom:'3px',marginTop:'3px',fontSize:'large',color:'blue'}}>{Number(article.prix)+Number(article.DelivPrix)} €</h2>
                            <span style={{color:'blue'}}>Avec Livraison</span>
                        </div>
                    </div>
                    <div id='PaiementModes' style={{marginTop:'10px'}}>
                        <span style={{fontWeight:'bold'}}> Modes de paiement : </span>
                        <hr/>
                        <div style={{paddingRight:'20px'}}>
                            {article.modesPaiement.map((paiement)=>{
                                let src = "";
                                if(paiement==="Cash")
                                    src = '/images/icons/paiements/cash.png';
                                else if(paiement==='Carte Bancaire')
                                    src  = '/images/icons/paiements/carte.png';
                                else if(paiement==='Paypal')
                                    src  = '/images/icons/paiements/paypal.png';
                                return(<img src={src} style={{margin:'5px 15px 5px 5px',marginLeft:'0px',width:'50px'}} />)
                            })}
                        </div>
                    </div>
                </div>
                <div id='description'>
                    <div>
                        <h3>Description du produit : </h3>
                        <p>
                            <TextPargraph textminlength={230} text={article.description} />
                        </p>
                    </div>
                    {ShowElementCompo()}
                    <div style={{marginTop:'10px'}}>
                        <input onClick={e=>{setDisplayDownloads(false);setCaracteristiquesColor("white");setDownloadsColor("lightgray");}} style={{backgroundColor:btnCaracteristiquesColor}} id='btnProprieties' type='button' value={'Caractéristiques'}/>
                        <input onClick={e=>{setDisplayDownloads(true);setCaracteristiquesColor("lightgray");setDownloadsColor("white");}} style={{backgroundColor:btnDownloadsColor}} id='btnDownloads' type='button' value={'Télechargements'}/> 
                        {displayDownloads()}
                    </div>
                    <div>
                        <h3>Autres informations : </h3>
                        <p>
                            <TextPargraph textminlength={230} text={article.infotech} />
                        </p>
                    </div>
                </div>
                <div id='avis' style={{marginBottom:'20px'}}>
                    <h3 style={{marginBottom:'0px'}}><span style={{marginRight:'20px'}}>Avis</span> 
                        <Rating size={20} initialValue={2.5} readonly={true} onClick={e=>{}} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} /> ({note}/5)</h3>
                    <hr style={{marginBottom:'20px'}}/>
                    {ShowNewAvis()}
                    <Avis isNew={false} avis={{'other':'Cheha Mohamed','note':2.5,'text':'Cet article est parfait. Commandez le sans attendre. Operationnel des reception oejzojhoze orhii efiohiohuirjze io jio joz orzeio jro jzeio riozehr hzeiohrize ihrzei riui ihrizehihi he izruih'}} />
                </div>
                
            </div>
        </div>
    )
}