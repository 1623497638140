import React, { useState, useEffect } from "react";
import './messagerie.css';

export default function Messagerie({App,EditUser,User,UpdateUser})
{
    const [btnSend,setBtnSend] = useState(false);
    const [user,setUser] = useState(User);
    const [newMessage,setNewMessage] = useState("");
    
    useEffect(()=>{
        user.messagerie.forEach((message)=>{
            message.lu = true;});
            UpdateUser(user);
        },[]);

    const SendMessage=()=>
    {
        const today = new Date(Date.now());
        user.messagerie.unshift({message:newMessage.replace("'"," "),date:today.toDateString(),author:'client',lu:true});
        setUser({...user});
        EditUser(user);
        UpdateUser(user);
    }

    const ShowMessages=()=>
    {
        return (user.messagerie.map((message)=>{
            if(message.author==='client')
            {
                return(
                    <div key={user.messagerie.indexOf(message)} className="User-Champs" style={{backgroundColor:'lightgreen',paddingLeft:'10px',paddingRight:'10px',marginLeft:'10px',border:'1px solid gray',paddingTop:'5px',textAlign:'right',borderRadius:'5px'}}>
                        <div style={{paddingLeft:'10px'}}><span style={{color:'black',fontSize:'small',marginTop:'0px',display:'inline-block'}}>({message.date})</span><span style={{display:'inline-block',fontWeight:'bold',marginLeft:'10px',marginRight:"10px",fontWeight:'bold'}}>Vous </span></div>
                        <hr />
                        <p style={{paddingLeft:'10px'}}>
                            {message.message}
                        </p>   
                    </div>
                );
            }
            else
            {
                return(
                    <div key={user.messagerie.indexOf(message)} className="User-Champs" style={{backgroundColor:'skyblue',paddingLeft:'10px',paddingRight:'10px',marginLeft:'10px',border:'1px solid gray',paddingTop:'5px',textAlign:'left',borderRadius:'5px'}}>
                        <div style={{paddingLeft:'10px'}}><span style={{display:'inline-block',fontWeight:'bold',marginRight:'10px'}}>Administrateur</span><span style={{color:'black',fontSize:'small',marginTop:'0px',display:'inline-block'}}>({message.date})</span> </div>
                        <hr />
                        <p style={{paddingLeft:'10px'}}>
                            {message.message}
                        </p>   
                    </div>
                );
            }
        }));
    }

    return(
        <div id="Messagerie" style={{height:'100%'}}>
            <div className="User-Champs" style={{padding:'5px',paddingRight:'10px',marginLeft:'5px',paddingTop:'5px',textAlign:'right'}}>
                <textarea placeholder={"Si vous voulez nous envoyer un message, c'est par ici ..."} onChange={e=>{setNewMessage(e.target.value);}} style={{padding:'10px',border:'1px solid lightgray',width:'96%',borderRadius:'5px',minHeight:'70px'}} />
                <input onClick={e=>{SendMessage();}}  type="button" value={"Envoyer"} style={{position:'relative',right:'0px',padding:'5px',marginTop:'10px'}}/>
            </div>
            {ShowMessages()}
        </div>
    );    
}