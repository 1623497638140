import React, { useState, useEffect,useContext } from "react";
import axios from 'axios';
import { BrowserView, MobileView } from "react-device-detect";
import './userorders.css';
import Payment from "./PayStatus";
import DelivryStatus from "./DelStatus";
import FetchData from "../../api/FetchApiData";
import { AppContext } from "../../../AppContextProvider";

export default function UserOrders()
{
    const [SelectedOrder,setSelectedOrder] = useState({});
    const [ShowStatus,setDisplayStatus] = useState('none');
    const [Orders,setOrders] = useState([]);
    const [AppState,setAppState] = useContext(AppContext);
    console.log(AppState.user);
    
    useEffect(()=>{
        const formData = new FormData();
        formData.append('target','order');
        formData.append('request','get-user-orders');
        var jresult = [];
        FetchData(formData).then((response)=>{
            if(typeof(response)==='string')
            {jresult = JSON.parse(response);}
            else
            {jresult=response;}
            jresult = jresult.map((item)=>{return JSON.parse(item);}) 
            setOrders(jresult);
        });
    },[]);
    

    const OrderDetails=()=>
    {
        switch(ShowStatus)
        {
            case 'payment':
                return <Payment setDisplayState={setDisplayStatus} Order={SelectedOrder} />;
            case 'delivry':
                return <DelivryStatus setDisplayState={setDisplayStatus} Order={SelectedOrder} />
            default:
                return;
        }
    }

    const ShowOrders=()=>
    {        
        const GetCurrentDelivryStatus=(order)=>
        {
            var currentstatus = "Attente de validation";
            order.Status.forEach((element)=>{

                if(element.status=== "en cours")
                {
                    console.log(element);
                    currentstatus = element.name;return;

                }
            })
            return currentstatus;
        }

        const GetRemainPayment = (order)=>
        {
            var rpayment = order.PrixTotal;
            order.PaymentHistoric.forEach(element => {
                if(element.status)
                {
                    rpayment = rpayment - element.montant;
                }
            });
            if(rpayment<0)
            {return 0;}
            return rpayment;
        }

        if(Orders.length===0)
        {
            return(
                <tr><td colSpan={4}>Aucune commande enregistrée</td></tr>
            );
        }
        else
        {
            return(
                Orders.map((order)=>{
                return (
                    <tr key={Orders.indexOf(order)} style={{fontSize:'smaller',borderRadius:'10px'}}>
                        <td style={{borderBottom:'1px solid gray'}} >{order.numero}</td>
                        <td onClick={(e)=>{setSelectedOrder(order);setDisplayStatus('payment')}} style={{borderBottom:'1px solid gray'}}>Reste : <span style={{fontWeight:'bold'}}>{GetRemainPayment(order)} €</span></td>
                        <td onClick={(e)=>{setSelectedOrder(order);setDisplayStatus('delivry')}} style={{borderBottom:'1px solid gray'}}>{GetCurrentDelivryStatus(order)}</td>
                        <td style={{borderBottom:'1px solid gray'}}><input style={{borderStyle:'none',backgroundColor:'#FF7F7F',padding:'5px',borderRadius:'2px'}} type="button" value="Annuler" /></td>
                    </tr>
                )
            }));
        }
        
    }

    return(
        <div id="orderhistory">
            <div>
                <table style={{fontSize:'small'}}>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor:'lightgray',borderTopLeftRadius:'5px'}}>Numéro</td>
                            <td style={{backgroundColor:'lightgray'}}>Paiement</td>
                            <td style={{backgroundColor:'lightgray'}}>Statut/Suivi</td>
                            <td style={{backgroundColor:'lightgray',borderTopRightRadius:'5px'}}>Annler</td>
                        </tr>
                        {ShowOrders()}
                    </tbody>     
                </table>    
            </div>
            {OrderDetails()}
        </div>
    );
}