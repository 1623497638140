import React, { useEffect, useContext, useState } from "react";
import './AjoutProduit.css';
import Vitrines from "../vitrines/Vitrines";
import FetchData from "../api/FetchApiData";
import Button from "../utils/ButtonLoader";
import { AppContext } from "../../AppContextProvider";

export default function AjoutProduit(props)
{
    const rayon = "photovoltaique";
    const [availableArticles,setAvailableArticles] = useState(props.avItems);
    const [selectedArticles,setSelectedArticles] = useState([]);
    const [data,setData] = useState({rayon:'Photovoltaique'});
    const [images,setImages] = useState([]);
    const [Caracteristiques,setCaracteristiques] = useState([{"name":"Poids","value":"10Kg"}]);
    const [files,setFiles] = useState([]);
    const [isKit,setIskit] = useState(false);
    const [phplog,setphplog] = useState("");
    const [modesPaiement,setModePaiement] = useState(['Cash']);
    const [loading,setLoading] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);

    const HandleData = (htmlElement)=>
    {
        data[htmlElement.target.name] = htmlElement.target.value;
    }

    useEffect(()=>{
        data['compokit'] = selectedArticles;
        data['caracteristiques'] = Caracteristiques;
        data['modesPaiement'] = modesPaiement;
        //console.log(data);
    },[selectedArticles,modesPaiement,Caracteristiques])

    const SelectKitItem = (item)=>
    {
        selectedArticles.push(item);
        setSelectedArticles([...selectedArticles]);
        console.log(selectedArticles);
    }

    const EditSelectedItem = (item,quantite)=>
    {
        var index = selectedArticles.indexOf(item);
        item.quantite = quantite
        selectedArticles[index] = item;
        setSelectedArticles([...selectedArticles]);
    }

    const RemoveSelectedItem=(article)=>
    {
        selectedArticles.splice(selectedArticles.indexOf(article),1);
        setSelectedArticles([...selectedArticles]);
    }

    const isKitChanged = (isKitValue)=>{
        if(isKitValue=="Oui")
            setIskit(true);
        else
            setIskit(false)
    }

    const ShowArticle = ()=>{
        if(isKit)
        {
            return(
            <div className="DivInput">
                <label>Articles </label>
                <div className="UserInput">
                    <fieldset>
                        <legend>Choisissez un article</legend>
                            <input type="search" placeholder="trouver un article" style={{width:"96%",margin:"5px"}} />
                            {
                                availableArticles.map((article)=>
                                {
                                    return(
                                        <div key={availableArticles.indexOf(article)} style={{width:"100%",backgroundColor:'orange',paddingTop:'5px',paddingBottom:'5px',marginBottom:'5px'}}>
                                            <div style={{width:'65%',display:'inline-block',verticalAlign:'middle'}}>
                                                <h5 style={{margin:'auto'}}>{article.nom}</h5>
                                                <span style={{fontSize:'small'}}>Ref : {article.reference}</span>
                                            </div>
                                            <input onClick={e=>{SelectKitItem(article)}} type="button" style={{display:'inline-block',width:'20%',verticalAlign:'middle'}} value={'+'} />
                                        </div>);
                                })
                            }
                
                    </fieldset>
                    <fieldset>
                        <legend>Element du kit</legend>
                        {
                            selectedArticles.map((article)=>
                            {
                                return(
                                    <div key={selectedArticles.indexOf(article)} style={{width:"100%",backgroundColor:'orange',paddingTop:'5px',paddingBottom:'5px',marginBottom:'5px'}}>
                                        <div style={{width:'65%',display:'inline-block',verticalAlign:'middle'}}>
                                            <h5 style={{margin:'auto'}}>{article.nom}</h5>
                                            <span style={{fontSize:'small'}}>Ref : {article.reference}</span>
                                            <div style={{width:'100%'}}>
                                                <label style={{display:'inline-block',width:'30%'}}>Qte : </label>
                                                <input onChange={e=>{EditSelectedItem(article,e.target.value)}} type="number" style={{display:'inline-block',width:'50%',verticalAlign:'middle'}} defaultValue={1} />
                                            </div>
                                        </div>
                                        <input onClick={e=>{RemoveSelectedItem(article)}} type="button" style={{display:'inline-block',width:'20%',verticalAlign:'middle'}} value={'-'} />
                                    </div>);
                            })
                        }
                    </fieldset>
                </div>
            </div>);
        }
        else
            return "";
    }

    const SaveArticle = ()=>
    {
        setLoading(true);
        var formData = new FormData();
        formData.append('target','article');
        formData.append('request','article-registration');
        formData.append('rayon',rayon);
        data["images"] = [];
        data["documents"] = [];
        data["avis"] = [];
        data["note"] = 5;
        images.forEach((image)=>
            {
                formData.append('image'+images.indexOf(image),image);
                console.log(image.name);
                data["images"].push("/articles/"+rayon+"/reference/images/"+image.name);
            });
        files.forEach((file)=>
            {
                formData.append('document'+files.indexOf(file),file);
                data["documents"].push("/articles/"+rayon+"/reference/documents/"+file.name)
            });
        formData.append('value',JSON.stringify(data));
        
        FetchData(formData).then((response)=>{
            console.log(response);setphplog(response);
            setLoading(false);
        });
        
    }

    const EditCaracteristique=(champ,value,index_car)=>
    {
        if(champ==="name")
            Caracteristiques[index_car].name = value;
        else
            Caracteristiques[index_car].value = value;
        setCaracteristiques([...Caracteristiques]);
    }

    const HandleModesPaiement = (element) =>
    {
        if(element.target.checked)
        {
            modesPaiement.push(element.target.value);
        }
        else
        {
            modesPaiement.splice(modesPaiement.indexOf(element.target.value),1);
        }
        setModePaiement([...modesPaiement]);
        console.log(modesPaiement);
    }


    return(
        <div id="AjoutProduit">
            <fieldset id="MainFieldset">
                <legend id="LegendTitle">
                    Ajouter un article
                </legend>
                <div className="DivInput">
                    <label>Article Composé : </label>
                    <select onChange={e=>{HandleData(e);isKitChanged(e.target.value);}} className="UserInput" name="isKit">
                        <option>Non</option>
                        <option>Oui</option>
                    </select>
                </div>
                <div className="DivInput">
                    <label>Titre : </label>
                    <input onChange={e=>{HandleData(e)}} className="UserInput" name="nom" type="text" placeholder="Titre de l'article"/>
                </div>
                <div className="DivInput">
                    <label>Rayon : </label>
                    <select onChange={e=>{HandleData(e)}} name="rayon" className="UserInput" type="text" placeholder="Rayon de l'article">
                        {AppState.rayons.map((rayon)=>{return <option>{rayon.name}</option>})}
                    </select>
                </div>
                <div className="DivInput">
                    <label>Catégorie : </label>
                    <input onChange={e=>{HandleData(e)}} name="categorie" className="UserInput" type="text" placeholder="type de produit"/>
                </div>
                <div className="DivInput">
                    <label>Fabricant : </label>
                    <input onChange={e=>{HandleData(e)}} name="fabricant" className="UserInput" type="text" placeholder="Fabricant"/>
                </div>
                <div className="DivInput">
                    <label>Prix (Euros) : </label>
                    <input onChange={e=>{HandleData(e)}} name="prix" className="UserInput" type="number" defaultValue={1000}/>
                </div>
                <div className="DivInput">
                    <label>Stock : </label>
                    <input onChange={e=>{HandleData(e)}} name="stock" className="UserInput" type="number" defaultValue={1000}/>
                </div>
                <div className="DivInput">
                    <label>Livraison : </label>
                    <select onChange={e=>{HandleData(e)}} name="DelivFrom" className="UserInput">
                        <option>Comores</option>
                        <option>France</option>
                    </select>
                </div>
                <div className="DivInput">
                    <label>Livraison (prix) : </label>
                    <input onChange={e=>{HandleData(e)}} name="DelivPrix" className="UserInput" type="number" defaultValue={1000}/>
                </div>
                <div className="DivInput">
                    <label>Description : </label>
                    <textarea onChange={e=>{HandleData(e)}} name="description" className="UserInput"/>
                </div>
                <div className="DivInput">
                    <label>Infos techniques : </label>
                    <textarea onChange={e=>{HandleData(e)}} name="infotech" className="UserInput"/>
                </div>
                <div className="DivInput">
                    <label>Paiements :</label>
                    <div className="UserInput">
                        <div className="PaiementCheckBox">
                            <input onChange={e=>{HandleModesPaiement(e)}} value="Paypal" type="checkbox" /><span>Paypal</span>
                        </div>
                        <div className="PaiementCheckBox">
                            <input onChange={e=>{HandleModesPaiement(e)}} checked value="Cash" type="checkbox" /><span>Cash</span>
                        </div>
                        <div className="PaiementCheckBox">
                            <input onChange={e=>{HandleModesPaiement(e)}} value="Carte Bancaire" type="checkbox" /><span>Carte bancaire</span>
                        </div>
                        <div className="PaiementCheckBox">
                            <input onChange={e=>{HandleModesPaiement(e)}} value="Virement Bancaire" type="checkbox" /><span>Virement bancaire</span>
                        </div>
                    </div>
                </div>
                <div className="DivInput">
                    <label>Images :</label>
                    <input accept="image/png,image/jpeg,image/jpg" onChange={e=>{setImages([...e.target.files])}} multiple type="file" name="images" className="UserInput"/>
                    <div style={{marginTop:'10px'}}>
                        {images.map((image)=>{
                            return <img key={image.name} style={{width:"100px",display:'inline-block',marginLeft:'10px'}} src={URL.createObjectURL(image)}  />
                        })}
                    </div>
                </div>
                <div className="DivInput">
                    <label>Documents :</label>
                    <input onChange={e=>{setFiles([...e.target.files])}} multiple accept="application/pdf" name="documents" type="file" className="UserInput"/>
                </div>
                <div className="DivInput">
                    <label>Caractéristiques :</label>
                    <div className="UserInput">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:"lightgray",}}><span>Nom</span></td>
                                <td style={{backgroundColor:"lightgray",}}><span >Valeur</span></td>
                                <td></td>
                            </tr>
                            {Caracteristiques.map((car)=>{
                                return(
                                <tr key={Caracteristiques.indexOf(car)}>
                                    <td><input style={{width:'100%'}} onChange={e=>{EditCaracteristique("name",e.target.value,Caracteristiques.indexOf(car))}} type="text" value={car.name}/></td>
                                    <td><input style={{width:'100%'}} onChange={e=>{EditCaracteristique("value",e.target.value,Caracteristiques.indexOf(car))}} value={car.value}  type="text" /></td>
                                    <td><input style={{width:'100%'}} value={"Supprimer"} onClick={e=>{Caracteristiques.splice(Caracteristiques.indexOf(car),1);setCaracteristiques([...Caracteristiques]);}} type="button" /></td>
                                </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr><td colSpan={3}><input style={{width:'100%'}} onClick={e=>{Caracteristiques.push({"name":"","value":""});setCaracteristiques([...Caracteristiques]);}} type="button" value={"Ajouter"} /></td></tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
                {ShowArticle()}
                <div className="DivInput">
                    <div className="SaveItem" style={{margin:'10px',width:'100px',height:'40px',borderStyle:'none',borderRadius:'10px',display:'inline-block'}}>
                        <Button style={{backGroundColor:'seagreen'}} loading={loading} onSubmit={e=>{SaveArticle();}} text={"Envoyer"} />    
                    </div>
                    <input style={{margin:'10px',width:'100px',height:'40px',backgroundColor:'orangered',borderStyle:'none',borderRadius:'10px',display:'inline-block'}} className="SaveItem" onClick={(e)=>{setAppState({...AppState,page:<Vitrines rayon="photovoltaique" />});}} type="button" value={"Annuler"} />
                    </div>
            </fieldset>
        </div>
    );
}