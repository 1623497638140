import axios from 'axios';
const AxiosApi = axios.create({withCredentials: true});
//const server = 'http://192.168.1.142';
//const server = 'http://localhost';
const server = 'https://asmina.fr/app/';

const FetchData = async (formData)=>
{
  var data = await AxiosApi.post(server, formData)
  .then((response)=>{
    return response.data;
  })
  .catch((error)=>{
    this.setState({phpLog:error});
  });
  return data;
};

export default FetchData;