import React, { useState, useEffect } from "react";
import "./user.css";
import 'react-phone-number-input/style.css';
import { BrowserView, MobileView } from "react-device-detect";
import Login from "./login/Login";
import Une from "../une/Une";
import Panier from "./panier/Panier";
import UserOrders from "./orders/UserOrders";
import InfoPerso from "./userinfo/UserInfos";
import Messagerie from "./messagerie/Messagerie";
import Orders from "./orders/Orders";
import Map from "../map/map";
import GlobalMap from "./globalmap/OrdersMap";
import { GridLayer } from "leaflet";
import { AppContext } from "../../AppContextProvider";
import { useContext } from "react";
import FetchData from "../api/FetchApiData";

export default function User(props)
{
    
    const [menu,setMenu] = useState("userInfos");
    const [AppState,setAppState] = useContext(AppContext);
    const [user,setUser] = useState(AppState.user);
    
    useEffect(()=>{
        setAppState({...AppState,backGroundImage:"none",
            navBar:{BackColor:"black",Color:"white"},
            navBtnH:{display:'inline-block'},
            navBtnR:{...AppState.navBtnR,image:"/images/icons/panier_white.png"}
        });
        /*
        const formData = new FormData();
        formData.append('target','client');
        formData.append('request','client-checklogin');
        formData.append('value','user');
        FetchData(formData).then((response)=>{
            var result = typeof(response)==='string' ? JSON.parse(response) : response;
            if(!result['login']) 
            {setAppState({page:<Login />});return;}
            else
            {
                result['user'] = typeof(result['user'])==='string' ? setUser(JSON.parse(result['user'])) : setUser(result['user']);
            }
        });*/
    },[]);
     
    const upDateUser = async (newuser)=>
    {
        const formData = new FormData();
        formData.append('target','client');
        formData.append('request','client-update');
        formData.append('value',JSON.stringify(newuser));
        let r = false;
        await FetchData(formData).then((response)=>{
            r = response;
        });
        EditUser(newuser);
        return r;
    }

    const EditUser = (newuser)=>
    {
        setUser(newuser);
        setAppState({...AppState,user:newuser});
    }

    const LogOut = ()=>
    {
        const formData = new FormData();
        formData.append('target','client');
        formData.append('request','client-logout');
        FetchData(formData).then((response)=>{
            console.log(response);
            var result = typeof(response)==='string' ? JSON.parse(response) : response;
            if(result['logout'])
            {setAppState({...AppState,page:<Une />,user:{auth:false,ad:false,labelname:"Espace Client",panier:{articles:[],lastview:[]}}});}
        });
    }

    const ShowContent=()=>
    {
        if(user!==null)
        {
            if(menu==="userInfos")
            {   return <InfoPerso EditUser={EditUser}  App={props.App} user={user} setCustomer={upDateUser} />}
            else if(menu==="orderHistory")
            {   return <UserOrders User={user} App={props.App} />}
            else if(menu==="panier" && AppState.user.panier.articles.length>0)
            {   return <Panier User={user} EditUser={EditUser} SaveUser={upDateUser} MainApp={props.App}  ChangePage={setMenu} />}
            else if(menu==="panier" && AppState.user.panier.articles.length===0)
            {
                return <InfoPerso EditUser={EditUser} user={user} MainApp={props.App} />;
            }
            else if(menu==="messagerie")
            {
                return <Messagerie EditUser={EditUser} UpdateUser={upDateUser} User={user}/>
            }
            else if(menu==="orders")
            {
                return <Orders />
            }
            else if(menu==='deconnecter')
            {
                LogOut();
            }
            else
            {return <InfoPerso EditUser={EditUser} user={user} MainApp={props.App} setCustomer={upDateUser} />;}
        }
    }

    const ShowMenu=()=>
    {
        if(user!==null)
        { 
            const UnReadMessages=()=>
            {
                var unReadMessagesCount = 0;
                user.messagerie.forEach((message)=>{
                    if(!message.lu)
                    {unReadMessagesCount++;}
                });
                return unReadMessagesCount;
            }
            
            const AdMenu=(ismobile)=>
            {
                if(AppState.user.ad)
                {
                    if(!ismobile)
                    {
                        return(
                            <div>
                                <label onClick={e=>{setMenu("orders")}}>Livraisons
                                    <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/orders.png" className="icon" />
                                </label>
                                <hr />
                            </div>
                        );
                    }
                    else
                    {
                        return(
                            <label onClick={e=>{setMenu("orders")}}>
                                <img style={{width:'35px',verticalAlign:'middle'}} src="/images/icons/orders.png" className="icon" />
                            </label>
                        );
                    }
                }
            }

            const StandardMenu = (ismobile = false)=>
            {
                if(!ismobile)
                {
                    return(
                        <div>
                            <hr />
                            <label onClick={e=>{setMenu("userInfos")}}>Infos personnelles</label>
                            <hr />
                            <label onClick={e=>{setMenu("orderHistory")}}>Commandes
                                    <span className="menu-notification" style={{backgroundColor:'gray'}}>
                                            {user.commandes.length}
                                    </span>
                                    <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/history.png" className="icon" />
                            </label>
                            <hr />
                            <label onClick={e=>{setMenu("panier")}}>Panier 
                                <span className="menu-notification" style={{backgroundColor:'orangered'}}>
                                        {AppState.user.panier.articles.length}
                                </span>
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/panier.png" className="icon" />
                            </label>
                            <hr />
                            <label onClick={e=>{setMenu("messagerie")}}>Messagerie
                                <span className="menu-notification" style={{backgroundColor:'blue'}}>
                                        {UnReadMessages()}
                                </span>
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/contact.jpg" className="icon" />
                            </label>
                            <hr />
                            {AdMenu(ismobile)}
                        </div>
                    );
                }
                else
                {
                    return(
                        <div>
                            <label onClick={e=>{setMenu("userInfos")}}>
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/infoprofil.png" className="icon" />
                            </label>
                            <label onClick={e=>{setMenu("orderHistory")}}>
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/history.png" className="icon" />
                                <span className="menu-notification" style={{backgroundColor:'gray'}}>
                                        {user.commandes.length}
                                </span>
                            </label>
                            <label onClick={e=>{setMenu("panier")}}> 
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/panier.png" className="icon" />
                                <span className="menu-notification" style={{backgroundColor:'orangered'}}>
                                        {AppState.user.panier.articles.length}
                                </span>
                            </label>
                            <label onClick={e=>{setMenu("messagerie")}}>
                                <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/contact.jpg" className="icon" />
                                <span className="menu-notification" style={{backgroundColor:'blue'}}>
                                        {UnReadMessages()}
                                </span>
                            </label>
                            {AdMenu(ismobile)}
                    </div>
                    );
                }
            }

            return(
            <div id="User-Menu">
                <BrowserView>
                    {StandardMenu(false)}
                    <label onClick={e=>{setMenu("deconnecter")}}>Deconnexion
                        <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/logout.png" className="icon" />
                    </label>
                    <hr />
                </BrowserView>
                <MobileView>
                    {StandardMenu(true)}
                    <label onClick={e=>{setMenu("deconnecter")}}>
                        <img style={{width:'20px',verticalAlign:'middle'}} src="/images/icons/logout.png" className="icon" />
                    </label>
                </MobileView>
            </div>);
        }
    }

    return(
        <div id="User">
            {ShowMenu()}
            {ShowContent()}
        </div>
    );
}