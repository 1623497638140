import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import React, { useState,useEffect, useContext, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L, { routing } from "leaflet";
import './map.css';
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";

function Routing(map,points=[])
{
  let Points = [];
  points.forEach((pt)=>{
    Points.push(L.latLng(pt.latitude, pt.longitude))
  })
  let leafletElement = L.Routing.control({
    waypoints: Points, 
    lineOptions: {
      styles: [
        {
          color: "blue",
          opacity: 0.6,
          weight: 4
        }
      ]
    },
    addWaypoints: false,
    draggableWaypoints: false,
    fitSelectedRoutes: false,
    showAlternatives: false,
    altLineOptions: { styles: [{opacity: 0}] },
    createMarker: () => { return null; }
  });
  leafletElement.addTo(map);
  return leafletElement;
}


function Repere({map,location,setWayPoints,WayPoints})
{
  if(isNaN(location.coords.latitude) || isNaN(location.coords.longitude))
  {return;}
  var iconurl = "/images/icons/map/localisation.png";
  var iconSize = [30,35];
  var popup =   <Popup><span style={{fontSize:'x-small',fontWeight:'bold'}}>{location.alias}</span>
                    <input style={{marginLeft:'10px',padding:'5px 10px 5px 10px',borderRadius:'5px',backgroundColor:'skyblue',fontSize:'x-small',borderStyle:'none'}} type="button" value={'Allez'} /> 
                </Popup>;
  try {
  switch(location.type)
  {
      case 'useradress':
        iconurl = "/images/icons/map/useradress.png";
        popup =   <Popup><span style={{fontSize:'x-small',fontWeight:'bold'}}>{location.alias}</span></Popup>
        break;
      case 'newuseradress':
        iconurl = "/images/icons/map/add_localisation.png";
        popup =   <Popup><span style={{fontSize:'x-small',fontWeight:'bold'}}>{location.alias}</span></Popup>
        break;
      case 'currentpostion':
        iconurl = "/images/icons/map/current.png";
        popup =   <Popup><span style={{fontSize:'x-small',fontWeight:'bold'}}>Vous êtes ici</span></Popup>
        iconSize = [30,30];
        break;
      case 'delivryadress':
        iconurl = "/images/icons/map/delivry.png";
        iconSize = [30,30];
        popup = <Popup><span style={{fontSize:'x-small',fontWeight:'bold'}}></span>
                      <div style={{display:'inline-block',fontSize:'x-small'}}>
                        <span style={{fontWeight:'bold'}}>{location.name}</span><br/>
                        <span style={{fontSize:'x-small'}}>Tel : {location.tel}</span><br/>
                      </div>
                      <input onClick={(e)=>{setWayPoints([...WayPoints,{latitude:location.coords.latitude,longitude:location.coords.longitude}])}} style={{marginLeft:'15px',height:'30px',padding:'5px 10px 5px 10px',borderRadius:'5px',backgroundColor:'yellowgreen',fontSize:'x-small',borderStyle:'none'}} type="button" value={'Itenéraire'} /> 
                </Popup>;
        break;
      default:
        break
  }
} catch (error) {}

  var CustomIcon = new L.Icon({
    iconUrl: iconurl,
    iconSize: iconSize,
    iconAnchor: [5, 30]
  });
  /*
  if(location.isNew)
  {
    CustomIcon = new L.Icon({
      iconUrl: NewLocationIcon,
      iconSize: [30, 40],
      iconAnchor: [5, 30]
    });
  }
    */
  return(
    <Marker icon={CustomIcon} position={[location.coords.latitude, location.coords.longitude]}>
        {popup}
    </Marker>
  );
}

export default function Map({zoom, UserManualAddMarker,locations,CenterIsCurrent=false}) {

  const [CurrentLocation,setCurrentLocation] = useState({alias:'vous',type:'currentpostion',status:false,coords:{latitude:-11.470978, longitude:43.387745}});
  const [WayPoints,setWayPoints] = useState([CurrentLocation.coords]);
  const [mapCenter,setMapCenter] = useState(CurrentLocation.coords);
  const MapTimer = useRef(
    setInterval(()=>
      {
        navigator.geolocation.getCurrentPosition((position)=>
        {
          setCurrentLocation({...CurrentLocation,coords:{latitude:position.coords.latitude,longitude:position.coords.longitude}});
          WayPoints[0] = CurrentLocation.coords;
          setWayPoints({...WayPoints});
        })
      },600000)
  );
 
  useEffect(()=>
  {
      if (navigator.geolocation) 
      {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if(!isNaN(position.coords.latitude) || !isNaN(position.coords.longitude))
              {
                setCurrentLocation({...CurrentLocation,coords:{latitude:position.coords.latitude,longitude:position.coords.longitude}});
                WayPoints[0] = CurrentLocation.coords;
                setWayPoints({...WayPoints});
                if(CenterIsCurrent)
                {setMapCenter({latitude:position.coords.latitude,longitude:position.coords.longitude});}
                else
                {setMapCenter(locations[0].coords)}
              }
            },
            (error) => {
              console.error('Error getting user location:', error);
            }
          );
      }
      else{console.error('Not available');}
      return(()=>{
        clearInterval(MapTimer);
      })

  },[])

  const [newLocation,setNewLocation] = useState(null);

  function MapView({points=[]}) {
    const map = useMap();
    map.setView([mapCenter.latitude, mapCenter.longitude], map.getZoom());
    map.on('click',(point)=>{
      if(UserManualAddMarker(point))
      {setNewLocation(<Repere setWayPoints={setWayPoints} WayPoints={WayPoints} location={{type:"newuseradress",alias:"Domicile",isNew:true,coords:{latitude:point.latlng.lat,longitude:point.latlng.lng}}} />)}
      else{setNewLocation("");}
    });
    let rout = null;
    useEffect(()=>{
      if(WayPoints.length>1)
      {
        rout = Routing(map,points);
        rout.hide();
      }
      return(()=>{
        if(rout!==null)
        {rout.remove()
          }
        })},
      [])
    return null;
  }
  
  return (
    <MapContainer
        className="map"
        center={[CurrentLocation.coords.latitude, CurrentLocation.coords.longitude]}
        zoom={zoom}
        scrollWheelZoom={true}>
        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>  contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {
            [...locations,CurrentLocation].map((location)=>{  
              return (<Repere setWayPoints={setWayPoints} WayPoints={WayPoints} location={location} />);}
            )
          }
        {newLocation}
      <MapView points={WayPoints} />
    </MapContainer>
  );
}
