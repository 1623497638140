import React, { useEffect, useState, useContext } from "react";
import Charge from "./Charge";
import Button from "../../../utils/ButtonLoader";
import './charges.css';
import FetchData from "../../../api/FetchApiData";
import { AppContext } from "../../../../AppContextProvider";

export default function Charges(props)
{
    const [availableCharges,setAvailableCharges] = useState([]);
    const [ChargesToDisplay,setChargesToDisplay] = useState([]);
    const [HomeCharges,setHomeCharges] = useState([]);
    const [display,setDisplay] = useState({display:'none',text:'+'});
    const [loading,setLoading] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);

    const AddChargeToHome = (charge)=>
    {
        HomeCharges.push(charge);
        setHomeCharges([...HomeCharges]);
    }

    const RemoveFromHomeCharges=(index)=>
    {
         HomeCharges.splice(parseInt(index),1);
         setHomeCharges([...HomeCharges]);
    }

    const ShowHomeCharges=()=>
    {
        if(HomeCharges.length===0)
        {
            return <p>
                Veuillez sélectionner les élements que vous utilisez chez vous parmi les élements suivants. 
                Si vous ne trouvez pas ce qu'il vous faut, faite une recherche sur le champs au dessus. 
            </p>
        }
        else
        {
            return (HomeCharges.map((charge)=>
                        <div key={HomeCharges.indexOf(charge)} className="HomeCharges">
                            <label>{charge.name+" ("+charge.quantite+")"}</label>
                            <input name={HomeCharges.indexOf(charge).toString()} onClick={e=>{RemoveFromHomeCharges(e.target.name)}} type="button" className="btnRemoveFromHome" value={'x'} />
                        </div>));
        }
    }

    useEffect(()=>{
        const formData = new FormData();
        formData.append('target','photovoltaique');
        formData.append('request','import-all-charges');
        setLoading(true)
        FetchData(formData).then((charges)=>{
            try{
                availableCharges.length = 0;
                charges.forEach((charge)=>{
                    availableCharges.push(JSON.parse(charge.object));
                })
                setAvailableCharges([...availableCharges]);
                setLoading(false);
            }
            catch
            {}
        })
    },[]);
    
    const HandleDisplay=()=>
    {
        if(display.display=='none')
        {setDisplay({display:'block',text:'-'});}
        else{setDisplay({display:'none',text:'+'});}
    }
    const NewCharge=()=>
    {
        if(AppState.user.ad)
            return(<Charge isNew={true} AddToHomeCharges={AddChargeToHome} charge={{name:"name",puissance:"2000",quantite:"1",duree:"8"}} />);
        else
            return;
    }

    return(
        <div id="Charges" style={{marginTop:'10px'}}>
            <div id="FiltresTitle">
                <div className="BtnDispFilters"><Button text={display.text} onSubmit={e=>{HandleDisplay()}} style={{backGroundColor:'whitesmoke',fontSize:'large'}} loading={loading}/></div>
                <span>Dimensionner</span>
            </div>
            <div style={{display:display.display,marginTop:'10px'}}>
                <input type="search" id="SearchCharge" placeholder="Trouver un composant"/>
                <div id="Home">
                    <img src="/images/icons/house.png" style={{width:"50px",height:"50px",float:"inline-start"}} />
                    { ShowHomeCharges() }
                </div>
                {NewCharge()}
                {
                    availableCharges.map((charge)=>{
                        return <Charge key={availableCharges.indexOf(charge).toString()} isNew={false} AddToHomeCharges={AddChargeToHome} charge={charge} />
                    })
                }
            </div>
        </div>
    );
}