import React, {useContext, useState} from "react";
import './vitrine.css';
import Produit from "../produit/Produit";
import {BrowserView, MobileView} from 'react-device-detect';
import { Rating } from 'react-simple-star-rating';
import { AppContext } from "../../AppContextProvider";
import Button from "../utils/ButtonLoader";
import FetchData from "../api/FetchApiData";

export default function Vitrine(props)
{
    const [loading,setLoading] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);
    const path = '/images/articles/photovoltaique/'+props.article.reference+'/images/';
    let itemTitle = props.article.nom;
    if(itemTitle.length>46)
    {
        itemTitle = itemTitle.substring(0,43)+' ...'
    }

    const SavePanier = ()=>
    {
        setLoading(true);
        const formData = new FormData();
        formData.append('target','client');
        formData.append('request','client-updateobject');
        delete AppState.user.auth;
        formData.append('value',JSON.stringify(AppState.user));
        FetchData(formData).then((response)=>{
            response = typeof(response)==='string' ? JSON.parse(response) : response;
            setLoading(false);
        });
    }

    const AddToPanier = ()=>
    {
        const found = AppState.user.panier.articles.find((element) => element.reference === props.article.reference);
        if(found!==undefined)
        {
            let index = AppState.user.panier.articles.indexOf(found);
            found.quantite = typeof(found.quantite)==='string' ? parseInt(found.quantite)+1 : found.quantite+1; 
            AppState.user.panier.articles[index] = found;
        }
        else{
            AppState.user.panier.articles.push({reference:props.article.reference,quantite:1,price:props.article.prix,delivry:0});
        }
        setAppState({...AppState});
        if(AppState.user.auth)
        {
            //SavePanier();
        }     
    }



    return(
        <div id="Vitrine" onClick={e=>{setAppState({...AppState,page:<Produit article={props.article} />})}}>
            <BrowserView>
                <div id="image">
                    <span>Prix : {props.article.prix} €</span>
                    <img src={props.article.images[0].replace("reference",props.article.reference)} /> 
                </div>
                <div id="infos">
                    <h5>{props.article.nom}</h5>
                    <div className="rating" style={{marginTop:'5px'}}>
                        <Rating size={20} readonly={false} initialValue={2.5} onClick={e=>{}} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} />
                    </div>
                    <span style={{color:'green'}}>{props.article.stock} en stock au <img className="flag" src="/images/icons/km_flag.svg" /></span>
                </div>
                <div id="btnAchat"><Button onSubmit={e=>{AddToPanier();}} style={{backGroundColor:'orange',fontSize:'small'}} loading={loading} text={"Acheter"} /></div>
            </BrowserView>
            <MobileView>
                <div id="image">
                    <span style={{fontWeight:'bold'}}>Prix : {props.article.prix} €</span>
                    <img src={props.article.images[0].replace("reference",props.article.reference)} /> 
                </div>
                <div id="infos">
                    <div className="title">
                        <label>{itemTitle}</label>
                    </div>
                    <div className="rating">
                        <Rating size={20} onClick={e=>{}} readonly={false} initialValue={2.5} onPointerEnter={e=>{}} onPointerLeave={e=>{}} onPointerMove={e=>{}} />
                    </div>
                    <div className="stock">
                        <span style={{color:'green'}}>{props.article.stock} en stock au <img className="flag" src="/images/icons/km_flag.svg" /></span>
                        <br/>
                        <span style={{color:'violet',fontSize:'x-small'}}>Delaie de livraison : 3 jours</span>
                    </div>
                    <input onClick={e=>{AddToPanier();}} type="button" id="btnAchat" value={"Acheter"} />
                </div>
            </MobileView>
        </div>
    );
}