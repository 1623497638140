import React from "react";
import './paystatus.css';
import Popup from "reactjs-popup";
import { AppContext } from "../../../AppContextProvider";
import { useContext } from 'react';

export default function PayStatus({Order,trigger})
{
    const [AppState,setAppState] = useContext(AppContext);
    const PaymentRow=(element)=>
        {
            let color = 'lightgray';
            let bcolor = 'white';
            let caissier = '';
            if(element.status)
            {   
                caissier = <label>{"Encaisser : ref :"+element.caissier}</label>;
                if(new Date(element.duedate) < new Date(element.paymentdate))
                {bcolor = 'orangered';}
                else{bcolor = 'lightgreen';}
            }
            else if(new Date(element.duedate) < new Date())
            {
                bcolor = 'orangered';
            }
            else
            {bcolor='lightgray';}
            let date = "";
            if(element.paymentdate!=null)
            {
                date = <div><label>{"le " + element.paymentdate.split('T')[0]}</label></div>; 
            }
            return(
            <div className="Row" style={{backgroundColor:bcolor}}>
                <div style={{textAlign:'left',display:'inline-block'}}>
                    <span style={{marginRight:'50px'}}>{element.duedate.split('T')[0]}</span><br/>
                    <span style={{marginRight:'50px',fontWeight:'bold'}}> Montant : {element.montant}  € </span>
                </div>
                <div style={{display:'inline-block',float:'right'}}>
                    <span style={{marginRight:'50px'}}>
                        <input type="button" disabled={element.status} style={{padding:'5px 10px 5px 10px',borderRadius:'5px',borderStyle:'none',marginRight:'10px'}} value={'Payer'} />
                        <input disabled={!element.status} style={{padding:'5px 10px 5px 10px',borderRadius:'5px',borderStyle:'none',marginRight:'10px'}} type="button" value={"Facture"}/>
                    </span>
                </div>
                <div style={{fontSize:'small',marginTop:'5px'}}>
                {caissier}
                    {date}
                    <label>{element.comment}</label>
                </div>
            </div>
                );
        }
        return(
        <Popup trigger={trigger} position={"center center"}>
            {close =>(
            <div className="OrderStatus" style={{minHeight:AppState.screenSize.height}}>
                    <fieldset>
                        <div style={{margin:'0px 0px 10px 0px'}}>
                            <a className="close" onClick={close}>
                                &times;
                            </a>
                        </div>
                        <legend>Paiements</legend>
                        {                    
                            Order.PaymentHistoric.map((element)=>{
                            let row = PaymentRow(element);
                            return(
                                <div key={Order.PaymentHistoric.indexOf(element)} className="OrderDetailsRow">
                                    {row}
                                </div>
                                );
                            })
                        }
                    </fieldset>
            </div>)}
        </Popup>);
    
}