import React, { useEffect, useState,useContext,useRef } from "react";
import './vitrines.css';
import Charges from "./filtres/photovoltaique/Charges";
import Vitrine from "./Vitrine";
import AjoutProduit from "../produit/AjoutProduit";
import Filtres from "./filtres/Filtres";
import {BrowserView, MobileView} from 'react-device-detect';
import Login from "../user/login/Login";
import User from "../user/User";
import { AppContext } from "../../AppContextProvider";
import FetchData from "../api/FetchApiData";

export default function Vitrines()
{
    let availableArticles = useRef([]);
    const [ItemToDisp,setItemToDisp] = useState([]);
    const [filtersData,setFiltersData] = useState([]);
    const [ShowMobileFilters,setShowMobileFilters] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);
    const [rayon,setRayon] = useState("photovoltaique");
    useEffect(()=>{
    setAppState({
            ...AppState,
            ShowSearchBar:true,
            navBtnL:
            {...AppState.navBtnL,
                'onClick':()=>{setShowMobileFilters(!ShowMobileFilters);},
                image:"/images/icons/navigationbar.png",
                display:'inline-block'
            },
            navBar:{BackColor:"black",Color:"white"},
            navBtnH:{display:'none'},
            navBtnR:
            {...AppState.navBtnR,
                'onClick':()=>
                    {
                        if(!AppState.user.authentification)
                        {
                            setAppState({...AppState,page:<Login />});
                        }
                        else
                        {
                            setAppState({...AppState,page:<User />});                       
                        }
                    },
                image:"/images/icons/panier_white.png"
            },
            backGroundImage:"none"
        });},[]);
    
    const import_article=()=>
    {
        var formData = new FormData();
        formData.append('target','article');
        formData.append('request','get-items');
        formData.append('conditions',{});
        formData.append('rayon','photovoltaique');
        FetchData(formData).then((response)=>{
            if(response!==undefined || response==[])
            {
                availableArticles.current = [];
                response.forEach((element) => {
                    try{
                        let item = JSON.parse(element);
                        availableArticles.current.push(item);
                    }
                    catch (error)
                    {}
                });
                setItemToDisp([...availableArticles.current]);
            }
        });
    }
    
    
    useEffect(()=>{
            import_article();
    },[]);

    const BtnNewItem = ()=>
    {
        if(AppState.user.ad)
            return(<input onClick={e=>{setAppState({...AppState,page:<AjoutProduit avItems={availableArticles} rayon={rayon} />})}} id="AddArticle" type="button" value='Ajouter un article' />);
        else
            return
    }

    const Page=()=>
    {
        if(ShowMobileFilters)
        {
            return(
                <div id="Filtres">
                    <input type="button" style={{width:"100%",backgroundColor:"lightgray",marginBottom:'10px',borderStyle:'none',height:'40px',borderRadius:'10px'}} value={"OK"} onClick={e=>{setShowMobileFilters(false);}} /> 
                    <Filtres Columns={filtersData} items={availableArticles} setToDisplay={setItemToDisp} />
                    <Charges />                   
                </div>
            );
        }
        else
        {
            return(
                <div>
                    <MobileView>
                        {BtnNewItem()}
                    </MobileView>
                    {ItemToDisp.map((item)=>{    
                        return <Vitrine key={ItemToDisp.indexOf(item)} article={item} />;
                    })}
                </div>
            );
        }
    }
    
    return(
        <div id="Vitrines">
            <BrowserView>           
                <div id="Filtres">
                    {BtnNewItem()}
                    <Filtres Columns={filtersData} items={availableArticles.current} setToDisplay={setItemToDisp}  />
                    <Charges />
                </div>
                <div id="Produits">
                    {Page()}
                </div>
            </BrowserView>
            <MobileView>
                <div id="Produits">
                    {Page()}
                </div>
            </MobileView>
        </div>
    );
}
