import React, { useContext, useEffect, useRef, useState } from 'react';

import Navigation from './components/navigation/Navigation';
import Une from './components/une/Une';
import './app.css';
import { AppContext } from './AppContextProvider';
import FetchData from './components/api/FetchApiData';
import Login from './components/user/login/Login';


export default function App()
{             

    const AppState = useContext(AppContext);
    const [state,setState] = useState(AppState);

    const CheckOnline=()=>
    {
      var formData = new FormData();
      formData.append('target','client');
      formData.append('request','client-checklogin');
      FetchData(formData).then((response)=>{
          response = typeof(response)==='string' ? JSON.parse(response) : response;
          if(response.login && !state.user.auth)
          {let user = JSON.parse(response.user);setState({...state,user:{...user,labelname:user.prenom+" "+user.name.toUpperCase(),auth:true}});}
          else if(!response.login && state.user.auth)
          {setState({...state,page:<Une />,user:{auth:false,ad:false,labelname:"Espace Client",panier:{articles:[],lastview:[]}}});}
          console.log(response);
      });
    }

    let AppTimer = useRef('');

    const savecunrentpositon = (positon)=>
    {
      const status = true;
      if(positon.coords.latitude === NaN || positon.coords.longitude === NaN)
        status = false;
      const currentposition = {status:status,latitude:positon.coords.latitude,longitude:positon.coords.longitude};
      if(state.user.auth)
      {
        var formData = new FormData();
        formData.append('target','client');
        formData.append('request','updatelocation');
        formData.append('value',JSON.stringify(currentposition));
        FetchData(formData).then((response)=>{
            //console.log(response);
            if(response!==undefined || response==[])
            {}
        });
      }
      else
      {}
    }

    useEffect(()=>
      {
        CheckOnline();
        AppTimer  = setInterval(()=>{CheckOnline();navigator.geolocation.getCurrentPosition((position)=>{savecunrentpositon(position);})},40000);
        return clearInterval(AppTimer);
    },[])
    
      return (
        <AppContext.Provider value={[state, setState]}>
          <div className="App" style={{width:state.screenSize.width,minHeight:state.screenSize.height,backgroundImage:state.backGroundImage,backgroundRepeat:'repeat'}}>
              <Navigation MobileNavBtnLClick={state.MobileNavBtnLClick} />
              <div id='Page' style={{position:'relative'}}>
                {state.page}
              </div>
          </div>
        </AppContext.Provider>
        );
   }



