import React, { useEffect, useState } from "react";

export default function TextPargraph(props)
{
    const[displayAll,setDisplayAll] = useState(false);
    const [displayedText,setDisplayedText] = useState(props.text);
    const [buttonText,setButtonText] = useState("  afficher plus ...")

    useEffect(()=>{
        if(displayAll)
        {
            setDisplayedText(props.text);
            setButtonText("afficher moins ...");
        }
        else
        {
            try{
                setDisplayedText(props.text.substring(0,props.textminlength));
                setButtonText("afficher plus ... ");
            }
            catch
            {}
        }
    },[displayAll]);

    return (
        <div>
            {displayedText}<span onClick={(e)=>{setDisplayAll(!displayAll);}} style={{color:"blue",marginLeft:"10px"}}>{buttonText}</span>
        </div>
    );
}