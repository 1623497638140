import React, {useContext} from "react";
import './paystatus.css';
import Popup from "reactjs-popup";
import { AppContext } from "../../../AppContextProvider";

export default function DelStatus({Order,trigger})
{
    const [AppState,setAppState] = useContext(AppContext);
    
    const DelivryRow=(element)=>
        {
            let livraison = null;
            let bcolor = 'lightgray';
            if(element.status==="en cours")
            {
                bcolor= 'orangered';
                if(element.name==='Validation ')
                {
                    livraison = <span style={{fontSize:'small',color:'black'}}>Pour validez votre commande, vous devez payer votre commande au magasin, en ligne ou chez un de nos 
                        nos buralistes proche de chez vous.
                    </span>;
                }

            }
            else if(element.status=="terminé")
            {bcolor="lightgreen"}
            let date = null;
            if(element.date!=null)
            {date = <label>{element.date}</label>;}
            if(element.livreur!=null)
            {
                livraison = <div style={{fontSize:'small',marginTop:'5px'}}>
                            <label>{"Livré par: "+element.livreur}</label>
                            {date}<br/>
                            <label> Commentaires : {element.comment}</label>
                        </div>;
            }

            return(
            <div  className="Row" style={{border:'1px solid '+bcolor,color:bcolor}}>
                <h3 style={{margin:'5px 0px 5px 0px'}}>{element.name +" "+element.status}</h3>
                {livraison}
            </div>
            );
        }

    return(
        <Popup trigger={trigger} position={"center center"}>
            {close =>(
                    <div className="OrderStatus" style={{minHeight:AppState.screenSize.height}}> 
                        <fieldset>
                            <legend>Livraison</legend>
                            <div style={{margin:'0px 0px 10px 0px'}}>
                                <a className="close" onClick={close}>
                                    &times;
                                </a>
                            </div>
                            {                    
                                Order.Status.map((element)=>{
                                    let row = DelivryRow(element);
                                    return(
                                        <div key={Order.Status.indexOf(element)} className="OrderDetailsRow">
                                            {row}
                                        </div>
                                    );
                                })
                            }
                        </fieldset>
                    </div>
        )}
        </Popup>
    );
}