import React, { useEffect, useState,useContext } from "react";
import './une.css';
import Vitrines from "../vitrines/Vitrines";
import Login from "../user/login/Login";
import User from "../user/User";
import { AppContext } from "../../AppContextProvider";

export default function Une(props)
{
    const [TimerIsCanceled,SetisCanceled] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);
    const [items,setitems] = useState([{
        image:"electronics.jpg",
        color:'black',
        title:"Répeteur Wifi Long porté pour une famille connecté et des maison intellignet",
        descriptif:'Nouvel arrivage de repeteurs wifi basse consommation pour une optimisation de vos consommation électrique tout en restant connecté. prix defiant toute concurence et livraison partout aux Comores'
    },
    {
        image:'pv.png',
        color:'white',
        title:'Vos batteries Lithium ions sont en promotion.',
        descriptif:'3 batteries achetés et -30% sur le quatrième. Livraison partout aux Comores, 3 jours après votre commande'
    },
    {
        image:'btp.jpg',
        color:'white',
        title:"Vos matériaux de construction sont disponibles",
        descriptif:'On a tout ce dont vous avez besoin pour votre villa. Ciment, fer 12, outils ... livraison gratuits à plus de 500 EUROS d achat',
    }]);
    const [Une,setUne] = useState(items[0]);
    
    useEffect(()=>{
        setAppState({   
                ...AppState,
                ShowSearchBar:true,
                navBtnR:
                {...AppState.navBtnR,
                    onClick:()=>
                    {
                        if(!AppState.user.authentification)
                        {
                            setAppState({...AppState,page:<Login />});
                        }
                        else
                        {
                            setAppState({...AppState,page:<User />});                       
                        }
                    },
                    image:"/images/icons/panier_white.png",
                    display:'inline-block'
                },
                navBtnH:{display:'inline-block'},
                navBtnL:
                {...AppState.navBtnL,
                    onClick:()=>
                    {},
                    image:"/images/icons/empty.png",
                    display:'none'
                },
                navBar:{BackColor:"transparent",Color:"white"},
                backGroundImage:"url(/images/une/"+Une.image+")"
            });
    },[])
    


    return(
        <div id="Une">
            <div id="InfoUne">
                <h1 style={{color:Une.color}} id="Title">{Une.title} </h1>
                <p style={{color:Une.color}} id="Text">{Une.descriptif}</p>
                <input onClick={(e)=>{setAppState({...AppState,page:<Vitrines />});SetisCanceled(true);}} id="Button" type="button" value={"Decouvrir"}/>
            </div>
        </div>
    );
}