import React, { useState, useEffect,useContext } from "react";
import Map from "../../map/map";
import './ordersmap.css';
import FetchData from "../../api/FetchApiData";
import { AppContext } from "../../../AppContextProvider";

export default function OrdersMap({close,Orders=[]})
{
    const [AppState,setAppState] = useContext(AppContext)
    const [Locations,setLocations] = useState([]);    
    useEffect(()=>{
        Orders.forEach((order)=>{
            Locations.push({...order.Location,type:'delivryadress'})
        });
        setLocations([...Locations]);
        //console.log(Locations);
        /*
        setOrders([]);
        const formData = new FormData();
        formData.append('target','order');
        formData.append('request','get-all-orders');
        var jresult = [];
        FetchData(formData).then((response)=>{
            if(typeof(response)==='string')
            {jresult = JSON.parse(response);}
            else
            {jresult=response;}
            jresult = jresult.map((item)=>{return JSON.parse(item);})
            setOrders(jresult);

        });
        */
        return(()=>{
            setLocations([]);
        });
    },[])
    
    return(
        <div id="MapContent" style={{height:AppState.screenSize.height}}>
            <Map UserManualAddMarker={()=>{}} zoom={15} locations={Locations} newLocation={null} />
            <a className="globalclosemap" onClick={close}>
                &times;
            </a>
        </div>);
}