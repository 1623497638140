import React, { useContext, useState } from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import './navigation.css'
import Une from "../une/Une";
import Login from "../user/login/Login";
import AjoutProduit from "../produit/AjoutProduit";
import User from "../user/User";
import Vitrines from "../vitrines/Vitrines";
import { AppContext } from "../../AppContextProvider";
import FetchData from "../api/FetchApiData";
import Filtres from "../vitrines/filtres/Filtres";
import Popup from "reactjs-popup";




function MobileNavigationBar(props)
{
    const [AppState,setAppState] = useContext(AppContext);
    return(
            <ul style={{maxWidth:'50%',listStyle:'none',display:'inline-block',verticalAlign:'middle',float:'left',verticalAlign:'middle'}}>
                <li className="Controls-li" onClick={e=>{setAppState({page:<Vitrines App={props.App} Proj={"BTP"} />})}}>
                    <img src="/images/icons/btp.jpg" style={{width:'30px',borderRadius:'10px',marginBottom:'-3px'}} />
                    <label style={{fontSize:'7px',marginTop:'0px'}}>Construction</label>
                </li>
                <li className="Controls-li" onClick={e=>{setAppState({page:<Vitrines App={props.App} Proj={"Energy"} />})}} >
                    <img src="/images/icons/btp.jpg" style={{width:'30px',borderRadius:'10px',marginBottom:'-3px'}} />
                    <label style={{fontSize:'7px',marginTop:'0px'}}>Energie</label> 
                </li>
                <li className="Controls-li" onClick={e=>{setAppState({page:<Vitrines App={props.App} Proj={"Energy"} />})}} >
                    <img src="/images/icons/btp.jpg" style={{width:'30px',borderRadius:'10px',marginBottom:'-3px'}} />
                    <label style={{fontSize:'7px',marginTop:'0px'}}>Energie</label> 
                </li>
            </ul>
            
    );
}

export default function Navigation(props)
{
    const [UserPanierIcon,setUserPanierIcon] = useState("/images/icons/panier_white.png");
    const [ShowNavBar,setShowNavBar] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);

    const LogOut = ()=>
        {
            const formData = new FormData();
            formData.append('target','client');
            formData.append('request','client-logout');
            FetchData(formData).then((response)=>{
                console.log(response);
                var result = typeof(response)==='string' ? JSON.parse(response) : response;
                if(result['logout'])
                {setAppState({...AppState,page:<Une />,user:{auth:false,ad:false,labelname:"Espace Client",panier:{articles:[],lastview:[]}}});}
            });
        }

    const UserHandle=()=>
    {
        if(AppState.user.auth)
        {
            return(
                <div className="SubControls">
                    <fieldset id="SubControlsFieldSet">
                        <legend id="triangle"></legend>
                        <ul className="SubControls-ul">
                            <hr/>
                                <li style={{height:'35px',paddingLeft:'20px',paddingRight:'10px'}} onClick={e=>{setAppState({...AppState,page:<User App={AppState} />})}} className="subControls-li">
                                    Infos Personnels
                                    <img src={"/images/icons/infoprofil.png"} style={{width:"35px",verticalAlign:"middle",marginLeft:"30%"}}  />
                                </li>
                            <hr/>
                                <li style={{height:'35px',textAlign:'left',paddingLeft:'20px'}} onClick={e=>{setAppState({...AppState,page:<User App={AppState} />})}} className="subControls-li">
                                    Votre Panier
                                    <img src={"/images/icons/orders.png"} style={{width:"35px",verticalAlign:"middle",marginLeft:"40%"}}  />
                                    <span style={{marginLeft:'-8px',Top:'-5px',backgroundColor:"rgb(255,0,0,0.8)",paddingLeft:"5px",paddingRight:"5px",borderRadius:'5px',verticalAlign:'top'}}>
                                        {AppState.user.panier.articles.length}
                                    </span>
                                </li>
                            <hr/>
                                <li style={{textAlign:'left',paddingLeft:'20px'}} onClick={e=>{LogOut();}} className="subControls-li">
                                    Deconnexion
                                    <img src={"/images/icons/logout.png"} style={{width:"30px",verticalAlign:"middle",marginLeft:"40%"}}  />
                                </li>
                            <hr/>
                        </ul>
                    </fieldset>
                </div>
            );
        }
        else
        {
            return(
            <div className="SubControls">
                <fieldset id="SubControlsFieldSet" style={{width:'100%'}}>
                    <legend id="triangle"></legend>
                    <ul className="SubControls-ul">
                            <li style={{border:'1px solid lightgray',height:'35px',textAlign:'left',paddingLeft:'20px'}} onClick={e=>{setAppState({...AppState,page:<User App={AppState} />})}} className="subControls-li">
                                Votre Panier
                                <img src={"/images/icons/orders.png"} style={{width:"35px",verticalAlign:"middle",marginLeft:"40%"}}  />
                                <span style={{marginLeft:'-8px',Top:'-5px',backgroundColor:"rgb(255,0,0,0.8)",paddingLeft:"5px",paddingRight:"5px",borderRadius:'5px',verticalAlign:'top'}}>
                                    {AppState.user.panier.articles.length}
                                </span>
                            </li>
                    </ul>
                    <input onClick={e=>{setAppState({...AppState,page:<Login ConForm={true} />})}} style={{border:'1px solid lightgray', padding:'10px 20px 10px 20px',borderStyle:'none',borderRadius:'5px',backgroundColor:'yellowgreen'}} type="button" value='Connexion' />
                    <input onClick={e=>{setAppState({...AppState,page:<Login ConForm={false} />})}} style={{padding:'10px 20px 10px 20px',borderStyle:'none',borderRadius:'5px',backgroundColor:'seagreen'}} type="button" value={'Inscription'} />
                </fieldset>
        </div>);
        }
    }

    const ShowSearChBar=()=>
    {
        if(AppState.ShowSearchBar)
        {
            return(
                <div id="SearchBar" >
                    <select id="SearchSelect">
                        <option>Construction</option>
                        <option>Energie</option>
                        <option>Prêt à porter</option>
                    </select>
                    <input id="searchbarinput" type="search" />
                    <img id="searchbarimg" src="/images/icons/searchbar.png"/>      
                </div>);
        }
    }

    function BrowserNavControls(props)
    {
        const [AppState,setAppState] = useContext(AppContext);
        return(
            <div id="Controls">
                    <ul id="Controls-ul">
                        {ShowSearChBar()}
                        <li style={{float:'right'}} onMouseLeave={e=>{props.setUserPanierIcon("/images/icons/panier_white.png");}} onMouseOver={e=>{props.setUserPanierIcon("/images/icons/panier.png");}} className="Controls-li">
                            <span id="labelname" onClick={e=>{setAppState({...AppState,page:<Login />});}}>{AppState.user.labelname}
                                <img src={props.UserPanierIcon} style={{width:"20px",verticalAlign:"middle",marginLeft:"10px"}}  />
                                <span style={{marginLeft:'-8px',Top:'-5px',fontSize:'small',backgroundColor:"rgb(255,0,0,0.8)",paddingLeft:"5px",paddingRight:"5px",borderRadius:'1px',verticalAlign:'top'}}>
                                    {AppState.user.panier.articles.length}</span>
                            </span>
                            {props.UserHandle()}
                    </li>
                    </ul>
                </div>
        );
    }

    const PopFilter=()=>
    {
        return(
            <Popup trigger={<img style={{display:AppState.navBtnL.display}} id="BtnNavL" src={AppState.navBtnL.image}  alt="navigation"/>}>
                <div style={{position:'fixed',top:'60px',left:'0px',height:AppState.screenSize.height,width:'100%',backgroundColor:'rgba(0,0,0,0.8)',zIndex:'10'}}>
                    <Filtres items={[]} setToDisplay={()=>{}} />
                </div>
            </Popup>
        );
    }

    //<img onClick={e=>{AppState.navBtnL.onClick();}} style={{display:AppState.navBtnL.display}} id="BtnNavL" src={AppState.navBtnL.image}  alt="navigation"/>
    return(
        <div id="Navigation" style={{backgroundColor:AppState.navBar.BackColor,color:AppState.navColor}}>
            <div id="Navigation-Container">
                <BrowserView>
                    <img onClick={e=>{setAppState({...AppState,page:<Une />})}} id="Logo" src="/images/icons/logo.png"  alt="Logo"/>
                    <img style={{height:'40px',display:AppState.navBtnH.display}} onClick={e=>{setAppState({...AppState,page:<Vitrines />})}} id="BtnNavH" src='./images/icons/home_white.png'  alt="navigation"/>
                    <BrowserNavControls UserHandle={UserHandle} UserPanierIcon={UserPanierIcon} setUserPanierIcon={setUserPanierIcon} />
                </BrowserView>
                <MobileView>
                    <div id="BtnToHome">
                        <img onClick={e=>{setAppState({...AppState,page:<Une />})}} id="Logo" src="/images/icons/logo.png"  alt="Logo"/>
                        <img onClick={e=>{setAppState({...AppState,page:<Vitrines />})}} id="BtnNavH" src='./images/icons/home_white.png'  alt="navigation"/>
                    </div>
                    <div id="BtnNavTo">
                        <PopFilter />
                        <img onClick={e=>{setAppState({...AppState,page:<Login />});}} style={{display:AppState.navBtnR.display}} id="BtnNavR" src={AppState.navBtnR.image}  alt="navigation"/>
                        <span id="notif" onClick={e=>{AppState.navBtnR.onClick();}}>
                            {AppState.user.panier.articles.length}
                        </span>
                    </div>
                    {ShowSearChBar()}
                </MobileView>           
            </div>
        </div>
    );
}