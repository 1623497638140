import React, { useState,useContext } from "react";
import ButtonLoader from '../../../utils/ButtonLoader';
import './charge.css';
import FetchData from "../../../api/FetchApiData";
import { AppContext } from "../../../../AppContextProvider";


export default function Charge(props)
{
    const [name,setName] = useState(props.charge.name);
    const [puissance,setPuissance] = useState(props.charge.puissance);
    const [duree,setDuree] = useState(props.charge.duree);
    const [quantite,setQuantite] = useState(1);
    const [loadState,setLoadState] = useState({loading:false,backGroundColor:'lightgray'});
    const [AppState,setAppState] = useContext(AppContext);
    let btnAddValue = "Ajouter";
    let backGroundBtnAdd = "lightGray";

    const AddToHomeCharges = ()=>
    {
        props.AddToHomeCharges({name:name,puissance:puissance,duree:duree,quantite:quantite});
        //props.setHomeCharges([...props.HomeCharges]);
    };

    const StoreNewCharge = ()=>
    {
        setLoadState({...AddToHomeCharges,loading:true});
        const formData = new FormData();
        formData.append('target','photovoltaique');
        formData.append('request','add-charge');
        formData.append('value',JSON.stringify({name:name,puissance:puissance,duree:duree,quantite:quantite}));
        FetchData(formData).then((response)=>{
            
            if(typeof(response)=='string')
            {response = JSON.parse(response);}
            if(response.ReqStatus)
            {
                setLoadState({loading:false,backGroundColor:'lightgreen'});
                AddToHomeCharges(response.result);
            }
            else
            {
                setLoadState({loading:false,backGroundColor:'orangered'});
                //AddToHomeCharges(response.result);
            }
            
            console.log(response);
        });
    }

    const SubmitButton = ()=>
    {
        if(!props.isNew)
        {
            return <input onClick={e=>{AddToHomeCharges()}} type="button" id="btnAdd" value={btnAddValue} style={{backgroundColor:backGroundBtnAdd}} />;
        }
        else
        {
            return <div style={{height:'25px',width:'150px',borderRadius:'5px',margin:'auto',marginTop:'5px'}}><ButtonLoader style={{backGroundColor:loadState.backGroundColor,fontSize:'small'}}  onSubmit={()=>{StoreNewCharge()}} text={"Nouvelle Charge"} loading={loadState.loading} disabled={false} /></div>;
        }
    }
    /*
    if(props.isNew)
    {
        btnAddValue = "Créer une charge";
        backGroundBtnAdd = "lightGreen";
        btnAddFunction = StoreNewCharge;
    }
    */
    return(
        <div id="Charge">
            <input id="name" onChange={e=>{setName(e.target.value)}} type="text" value={name} />
            <div>
                <label htmlFor="" className="labels" >Puissance : <span className="units">({puissance} Watts)</span> </label>
                <input type="range" onChange={e=>{setPuissance(e.target.value)}} value={puissance} className="input-range" step={1} min={0} max={15000} />
            </div>
            <div>
                <label htmlFor="" className="labels" >durée d'utilisation : <span className="units">({duree} Heures)</span> </label>
                <input type="range" onChange={e=>{setDuree(e.target.value)}} className="input-range" value={duree} step={1} min={0} max={24} />
            </div>
            <div>
                <label> Quantité : </label>
                <input id="Qty" type="number" onChange={e=>{setQuantite(e.target.value)}} value={quantite}/>
                {SubmitButton()}
            </div>
        </div>
    );
}