import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { BrowserView, MobileView } from "react-device-detect";
import ItemInPanier from "./ItemPanier";
import { AppContext } from "../../../AppContextProvider";
import FetchData from "../../api/FetchApiData";
import './panier.css';

export default function Panier({ChangePage,SaveUser})
{
    const [AppState,setAppState] = useContext(AppContext);
    const [Prices,setPrices] = useState({items:0,delivry:0,total:0})
    const [items,setItems] = useState(AppState.user.panier.articles);
    const [WithDeliv,setWithDeliv] = useState(false);
    const [Adress,setAdress] = useState({ville:'Inconnu',name:"",coords:{}})
    const [PaymentMode,setPaymentMode] = useState({mode:{paypal:true,cash:false},xfois:1});
    
    const SetCommandAdress=()=>
    {
        setAdress({ville:Adress.ville,name:Adress.alias,coords:Adress.coords});
    }

    useEffect(()=>{
        if(WithDeliv)
        {
            let Total = 0;
            let Delivry = 0;
            let Itemsprice = 0;
            for(let i=0;i<AppState.user.panier.articles.length;i++)
            {
                Itemsprice = Itemsprice+parseInt(items[i].price)*parseInt(items[i].quantite);
                Delivry = Delivry + parseInt(items[i].delivry);
                Total = Total + parseInt(items[i].price)*parseInt(items[i].quantite) + parseInt(items[i].delivry);
                console.log({total:Total,delivry:Delivry,items:Itemsprice});
            }
            setPrices({total:Total,delivry:Delivry,items:Itemsprice});
        }
        else
        {
            setPrices({...Prices,delivry:0});
        }
    },[WithDeliv])

    const UpdateItems = (item,saveuser=false)=>
    {
        let Total = 0;
        let Delivry = 0;
        let Itemsprice = 0;
        for(let i=0;i<AppState.user.panier.articles.length;i++)
        {
            if(item.reference===AppState.user.panier.articles[i].reference)
            {
                items[i] = item;
            }
            Itemsprice = Itemsprice+parseInt(items[i].price)*item.quantite;
            if(WithDeliv)
                Delivry = Delivry + parseInt(items[i].delivry);
            Total = Total + parseInt(items[i].price)*item.quantite + parseInt(items[i].delivry);
            console.log({total:Total,delivry:Delivry,items:Itemsprice});
        }
        setPrices({total:Total,delivry:Delivry,items:Itemsprice});
        setItems([...items]);
        AppState.user.panier.articles = items;
        setAppState({...AppState,panier:AppState.user.panier});
        if(saveuser)
        {
            SaveUser(AppState.user);
        }
        
    }
    
    const RemoveItem = (item)=>
    {
        let index = items.indexOf(item);
        items.splice(index,1);
        if(items.length===0)
        {
            setItems([]);
            let cp_user = AppState.user;
            cp_user.panier.articles = [];
            setAppState({...AppState,user:cp_user});
            ChangePage("userInfos");
        }
        else
        {
            setItems([...items]);
            let cp_user = AppState.user;
            cp_user.panier.articles = items;
            setAppState({...AppState,user:cp_user});
        }
        UpdateItems(item,true)
        //SaveUser(AppState.user);
    }

    const SaveCommand = async (order)=>
    {
        const formData = new FormData();
        order = {...order,email:AppState.user.email,ville:'Ville'};
        formData.append('target','order');
        formData.append('request','add-order');
        formData.append('email',AppState.user.email);
        formData.append('ville','Ville');
        formData.append('value',JSON.stringify(order));
        var result = false;
        await FetchData(formData).then((response)=>{
            result = response;
        });
        return result;
    }

    const Buy=()=>
    {
        let today = new Date();
        let PaymentSteps = [];
        let delivrySteps = [
            {name:"Validation ",status:'en cours',date:today,livreur:null,comment:"aucun commentaire"},
            {name:"Preparation ",status:'a venir',date:null,livreur:null,comment:'aucun commentaire'},
            {name:"Expedition ",status:'a venir',date:null,livreur:null,comment:'aucun commentaire'},
            {name:"Livraison ",status:'a venir',date:null,livreur:"l information n est pas encore disponible",comment:'aucun commentaire'},
        ]
        if(PaymentMode.xfois>1)
        {
            for(let i=0;i<PaymentMode.xfois;i++)
            {
                PaymentSteps.push({caissier:"234",duedate:new Date(today.toISOString()),paymentdate:null,comment:"Vous devez allez valider votre commande chez un de nos buralistes parténaires",montant:Prices.total/PaymentMode.xfois,status:false});
                today.setMonth(today.getMonth()+1);
            }
        }
        else if(PaymentMode.cash)
        {
            PaymentSteps.push({caissier:"234",duedate:today,paymentdate:null,comment:"Vous devez allez valider votre commande chez un de nos buralistes partenaires",montant:Prices.total,status:false});
            delivrySteps = [
                {name:"Validation ",status:'en cours',date:today,livreur:null,comment:"Vous devez validez votre commande chez un de nos buralistes partenaires."},
                {name:"Preparation ",status:'a venir',date:null,livreur:null,comment:'aucun commentaire'},
                {name:"Expedition ",status:'a venir',date:null,livreur:null,comment:'aucun commentaire'},
                {name:"Livraison ",status:'a venir',date:null,livreur:"l information n est pas encore disponible",comment:'aucun commentaire'},
            ]
        }
        else
        {
            PaymentSteps.push({caissier:"paypal",duedate:today,paymentdate:null,comment:"aucun",montant:Prices.total,status:true});
            delivrySteps = [
                {name:"Validation ",status:'terminé',date:today,livreur:null,comment:"Votre commande a bien été validé."},
                {name:"Preparation ",status:'en cours',date:null,livreur:null,comment:'aucun commentaire'},
                {name:"Expedition ",status:'a venir',date:null,livreur:null,comment:'aucun commentaire'},
                {name:"Livraison ",status:'a venir',date:null,livreur:"l information n est pas encore disponible",comment:'aucun commentaire'},
            ]
        }

        let panier = {numero:"1234",date:today,PrixTotal:Prices.total,Status:delivrySteps,Articles:[],PaymentMode:PaymentMode,PaymentHistoric:PaymentSteps,isCanceled:0,Location:Adress};
        AppState.user.panier.articles.forEach((item)=>{
            panier.Articles.push({reference:item.reference,name:item.nom,quantite:item.quantite,prixunit:item.prix});
        });

        SaveCommand(panier).then((result)=>{
            if(result.ReqStatus)
            {
                AppState.user.commandes.push(result.result);
                AppState.user.panier = {articles:[],lastview:[]};
                if(SaveUser(AppState.user))
                {
                    setAppState({...AppState,user:{...AppState.user,panier:AppState.user.panier}});
                }
            }
        });
    }

    const PaymentXfois=()=>
    {
        if(Prices.total>300)
        {
            return(
                <div className="PaymentMode" style={{width:'100%'}}>
                    <fieldset style={{width:'95%',margin:'auto',borderRadius:'5px',border:'1px solid gray',marginTop:'10px'}}>
                        <legend style={{fontSize:'small',border:'1px solid gray',borderRadius:'5px',padding:'1px 15px 1px 15px'}}>Payez en plusieurs fois</legend>
                        <img className="PaymentModeImg" style={{verticalAlign:'middle',width:"40px"}} src="/images/icons/paiements/cashpfois.png" />
                        <input type="number" max={Math.floor(Prices.total/300)} onChange={(e)=>{e.target.value<Math.floor(Prices.total/300) ? setPaymentMode({...PaymentMode,xfois:e.target.value}) : setPaymentMode({...PaymentMode,xfois:Math.floor(Prices.total/300)})}} value={PaymentMode.xfois}  style={{verticalAlign:'middle',marginLeft:'20px'}} />
                        <p style={{fontSize:'small'}}>Un crédit vous engage et doit être payé à temps </p>
                    </fieldset>
                </div>
            );
        }
    }
   
    return(
        <div id="Panier" >
            <div id="Panier-Container">
            {
                items.map((item,index)=>{
                    return <ItemInPanier key={items.indexOf(item)} ItemRef={item} SetCommandAdress={SetCommandAdress} UpdateItems={UpdateItems} RemoveItem={RemoveItem} />
                    })
            }
            </div>
            <div id="PaymentBlock">
                <fieldset id="DelivryBlock">
                    <div id="Title" style={{borderTopLeftRadius:'5px',borderTopRightRadius:'5px'}}>
                        <img src="/images/icons/delivry_black.png"/>
                        <span>Vos Livraisons</span>
                    </div>
                    <div id="DeliveryInfo">
                        <div id="Info" style={{fontSize:'small'}}>Livraison chez vous pour :</div>
                        <div id="Price">{Prices.delivry} €</div>
                    </div>
                    <div id="DelivryCheckBox">
                        <label style={{verticalAlign:'middle',width:'max-content',marginRight:'5px',fontSize:'small'}}>Livraison :</label>
                        <input style={{verticalAlign:'middle'}} disabled={(AppState.user.locations.length<1)} type="checkbox"  onChange={e=>{setWithDeliv(e.target.checked);}}  />
                    </div>
                    <div style={{marginTop:'10px'}}>
                        <select style={{width:'90%'}} id="AdressLivraison" disabled={!WithDeliv} onChange={e=>{}}>
                            {AppState.user.locations.map((location)=>{
                                return <option key={AppState.user.locations.indexOf(location)}>{location.alias}</option>
                            })}
                        </select>
                    </div>
                </fieldset>
                <fieldset id="PaymentInfo">
                    <div id="Title">
                        <img style={{height:'30px',width:'30px',padding:'5px'}} src="/images/icons/panier.png"/>
                        <span>Vos Achats</span>
                    </div>
                    <div className="PaymentInfoItem"><label>Articles : </label><span>{Prices.items} €</span></div>
                    <div className="PaymentInfoItem"><label>Promos : </label><span>0 €</span></div>
                    <div className="PaymentInfoItem" style={{fontSize:'large',fontWeight:'bold',marginBottom:'10px'}}><label>Total </label><span style={{float:'right'}}>{Prices.total} €</span></div>
                </fieldset>
                <fieldset id="PaymentModes">
                    <div id="Title">
                        <img src="/images/icons/paiements/payment.png" style={{width:'30px'}}/>
                        <span>Modes de paiement</span>
                    </div>
                        <div className="PaymentMode" style={{margin:'5px'}}>
                            <img className="PaymentModeImg" style={{width:'50px',verticalAlign:'middle'}} src="/images/icons/paiements/paypal.png" />
                            <input type="checkbox" onChange={e=>{setPaymentMode({paypal:true,cash:false,cash3x:false})}} checked={PaymentMode.paypal} style={{verticalAlign:'middle'}} />
                        </div>
                        <div className="PaymentMode" style={{margin:'5px'}}>
                            <img className="PaymentModeImg" style={{width:'50px',verticalAlign:'middle'}} src="/images/icons/paiements/cash.png" />
                            <input type="checkbox" onChange={e=>{setPaymentMode({paypal:false,cash:true,cash3x:false})}} checked={PaymentMode.cash} style={{verticalAlign:'middle'}} />
                        </div>
                        <div className="PaymentMode" style={{margin:'5px'}}>
                            <img className="PaymentModeImg" style={{width:'50px',verticalAlign:'middle'}} src="/images/icons/paiements/carte.png" />
                            <input type="checkbox" onChange={e=>{setPaymentMode({paypal:false,cash:false,cash3x:true})}} checked={PaymentMode.cash3x} style={{verticalAlign:'middle'}} />
                        </div>
                    {PaymentXfois()}
                </fieldset>
                    <input onClick={e=>Buy()} type="button" value={'Commander'} style={{width:'100%',height:'50px',backgroundColor:'orangered',borderStyle:'none',borderRadius:'5px',marginTop:'5px'}} />
            </div>
        </div>);
}