import React, { useState, useEffect,useContext } from "react";
import TextPargraph from "../../utils/TextParagraph";
import { BrowserView, MobileView } from "react-device-detect";
import FetchData from "../../api/FetchApiData";
import { ReactComponent as Loader } from './loader.svg';
import './spinner.css';
import { AppContext } from "../../../AppContextProvider";
import './itempanier.css';

export default function ItemInPanier({ItemRef,UpdateItems,RemoveItem})
{
    const [item,setItem] = useState(null);
    const [TotalPrice,setTotalPrice] = useState(0);
    const [AppState,setAppState] = useContext(AppContext);
    useEffect(()=>{
        var formData = new FormData();
        formData.append('target','article');
        formData.append('request','get-items');
        formData.append('conditions',{reference:ItemRef.reference});
        formData.append('rayon','photovoltaique');
        FetchData(formData).then((response)=>{
            if(response!==undefined)
            {
                response[0] = typeof(response[0])==='string' ? JSON.parse(response[0]) : response[0];
                response[0].quantite = ItemRef.quantite;
                setItem(response[0]);
                setTotalPrice(parseInt(response[0].prix)*ItemRef.quantite);
                console.log(ItemRef);
                UpdateItems({...ItemRef,delivry:response[0].DelivPrix},false);
            }
        });
    },[]);

    var DelivryInputDisable = false;
    
    if(AppState.user.locations.length==0)
    {DelivryInputDisable = true;}

    const UpDateQte=(quantite)=>
    {
        if(item !== null)
        {
            ItemRef.quantite = quantite;
            setTotalPrice(quantite*parseInt(item.prix));
            UpdateItems(ItemRef,true);
        }
    }

    if(item !== null)
    {
        return(
                <fieldset className="Panier-Item" style={{backgroundColor:'white'}}>
                    <img src={item.images[0].replace("reference",item.reference)} style={{float:'left'}} />
                    <h3>{item.nom}</h3>
                    <h4 >{item.reference}</h4>
                    <BrowserView>
                        <TextPargraph text={"ojsofjsdojoj osdjfioj pkpsjdoj jsojf oojdfso ojojsof o joosfjo joj sdfojo jsdfoj ojo jfsdojio jojsdfio jo"} textminlength={100} />                
                        <div className="livraison">
                            <span >
                                Livraison depuis {item.DelivFrom} jusqu'à chez pour {item.DelivPrix} €
                            </span>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="livraison">
                            <span >
                                Livraison chez vous pour {item.DelivPrix} €
                            </span>
                        </div>
                    </MobileView>
                    <div className="Controls">
                        <div style={{marginTop:'5px'}}>
                            <div >
                                
                            </div>
                        </div>
                        <div style={{marginTop:'20px'}}>
                            <label>Quantité :</label>
                            <input min={1} onChange={e=>{UpDateQte(e.target.value)}} type="number" defaultValue={item.quantite} style={{width:'100px'}}/>
                            <span style={{fontWeight:'bolder',marginLeft:'10px'}}>{TotalPrice} €</span>
                            <input onClick={e=>{RemoveItem(item)}} style={{float:'right',backgroundColor:'redorange',borderStyle:'none',borderRadius:'5px',padding:'5px 10px',margin:'10px 10px 0px 10px'}} type="button" value={'Supprimer'} />
                        </div>
                    </div>
                </fieldset>
        );
    }
    else
    {
        return(
            <div id="Panier-Container" className="User-Champs">
                <Loader className="spinner" />
            </div>
        );
    }
}