import React, { useState, useEffect, useRef,useContext } from "react";
import "./userinfos.css";
import "../user.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Map from "../../map/map";
import { BrowserView, MobileView } from "react-device-detect";
import Popup from "reactjs-popup";
import { AppContext } from "../../../AppContextProvider";
import Button from "../../utils/ButtonLoader";
import FetchData from "../../api/FetchApiData";

export default function InfoPerso(props)
{
    const [user,setUser] = useState(props.user);
    const [Locations,setLocations] = useState(props.user.locations);
    const [pays,setPays] = useState("Comores");
    const [KmCity,SetKmCity] = useState("Inconnu");
    const [loading,setLoading] = useState(false);
    const [AppState,setAppState] = useContext(AppContext);

    useEffect(()=>{
        async function SearchLocation(ville)
        {
        }
        SearchLocation(KmCity);
    },[KmCity]);

    const RemoveLocation = (name)=>
    {
        var LocArray = [];
        Locations.forEach(location => {
            console.log(location,name);
            if(location.alias!==name)
            {
                console.log(location);
                LocArray.push(location);
            }
        });
        setLocations(LocArray);
        setUser({...user,locations:LocArray});
        setAppState({...AppState,user});
        //props.EditUser(user);
    }
    
    const UserAdressesMap = ()=>
    {
        const [newLocation,setNewLocation] = useState({alias:"",coords:""});
        const [newLocationMode,setNewLocationMode] = useState("auto");
        const [loading,setLoading] = useState(false);

        const NewLocationForm=()=>
        {
            return(
            <fieldset id="addLocationForm">
                <select style={{width:'100%',marginTop:'5px'}} onChange={e=>{if(e.target.value==="Manuel"){setNewLocationMode('manual');setNewLocation({alias:'Domicile',type:'newuseradress',isNew:true,coords:{latitude:-11.6931255,longitude:43.2543044}});}else{setNewLocationMode('auto');}}}>
                    <option>Votre postion</option>
                    <option>Manuel</option>
                </select>
                <input style={{marginTop:'5px',textAlign:'center'}} placeholder="Chez maman cheikh" onChange={e=>{newLocation.alias = e.target.value}} />
                <div style={{borderStyle:'none',position:'relative',marginTop:'5px',height:"25px",width:'100%',borderRadius:'5px'}}>
                    <Button onSubmit={e=>{ValidateNewLocation();}} loading={loading} style={{backGroundColor:'lightgreen'}} text={'Ajouter'}/>
                </div>
            </fieldset>)
        }

        var ManualAddMarker = (point)=>
        {
            if(newLocationMode==='manual' && newLocation.coords!=="")
            {
                setNewLocation({alias:newLocation.alias,isNew:true,coords:{latitude:point.latlng.lat,longitude:point.latlng.lng}});
                    //newLocation.coords = {latitude:point.latlng.lat,longitude:point.latlng.lng};
                return true;
            }
            else{
                return false;
            }
        }

        const ValidateNewLocation=()=>
        {
                if(newLocation!=="" && newLocationMode ==='manual')
                {
                    user.locations.push({type:'useradress',ville:KmCity,alias:newLocation.alias,isNew:false,coords:newLocation.coords});
                    setUser({...user});
                    setLocations(user.locations);
                    setAppState({...AppState,user:{...user}})
                    setNewLocation("");
                }
                else
                {
                    setLoading(true);
                    navigator.geolocation.getCurrentPosition((position)=>{
                        user.locations.push({type:'useradress',ville:"ville",alias:newLocation.alias,isNew:false,coords:position.coords});
                        setUser({...user});
                        setLocations(user.locations);
                        setAppState({...AppState,user:{...user}})
                        setNewLocation("");
                        setLoading(false);
                    });
                }
                
        }

        return(
            <Popup trigger={<input type="button" value={'Ajouter'} />} position={"center center"}>
            { close=>(
                    <div id="MapDiv" style={{width:AppState.screenSize.width}}>
                        <div id="UserMap" style={{height:'100%'}}>
                            <Map UserManualAddMarker={ManualAddMarker} zoom={15} locations={Locations} newLocation={newLocation} />
                        </div>
                        <a style={{}} className="closemap" onClick={close}>
                            &times;
                        </a>
                        {NewLocationForm()}

                    </div>
            )}
            </Popup>
        );
    }

    const ShowAdress=()=>
    {
        if(pays==="Comores")
        {
            return(
                <div>
                    <div className="User-Champs">
                        <label>pays : </label>
                        <select className="input">
                            <option>Comores</option>
                            <option>France</option>
                        </select>
                    </div>
                    <div style={{width:"100%"}}>
                                {Locations.map((location)=>{
                                    return (<div className="LocationBlock">
                                        <label>{location.alias}</label>
                                        <input onClick={e=>{RemoveLocation(location.alias)}} type="button" className="LocationBlockRemove" value={'x'} />
                                    </div>);
                                })}
                                <UserAdressesMap />
                    </div>
                </div>
            )
        }
        else
        {
            return(
                <div className="User-Champs">
                    <label>Adresse :</label>
                    <input type="text" placeholder="Votre Adresse" />
            </div>
            );
        }
    }

    const SaveUser = async (newuser)=>
    {
            setLoading(true);
            const formData = new FormData();
            formData.append('target','client');
            formData.append('request','client-update');
            formData.append('value',JSON.stringify(newuser));
            let r = false;
            await FetchData(formData).then((response)=>{
                r = response;
                setLoading(false);
            });
            setAppState({...AppState,user:newuser});
            return r;
    }

    return(
        <div id="User-Infos" style={{margin:'5px',paddingBottom:"20px"}}>
            <fieldset>
                <div className="User-Champs">
                    <label>Nom : </label>
                    <input className="input" onChange={(e)=>{user.name=e.target.value;const u = user;setUser(u);}} type="text" placeholder="votre nom" defaultValue={user.name}/>
                </div>
                <div className="User-Champs">
                    <label>Prenom : </label>
                    <input className="input" onChange={(e)=>{user.prenom=e.target.value;const u = user;setUser(u);}} type="text" placeholder="votre prenom" defaultValue={user.prenom} />
                </div>
                <div className="User-Champs">
                    <label>Email : </label>
                    <input onChange={e=>{user.email=e.target.value;const u = user;setUser(u);}} className="input" type="email" placeholder="votre email" defaultValue={user.email} />
                </div>
                <div className="User-Champs">
                    <label>Téléphone : </label>
                    <div style={{width:'52%',display:'inline-block'}}>
                        <PhoneInput countries={["KM","FR"]} con onChange={(e)=>{user.phone.number = e}} onCountryChange={(e)=>{user.phone.country = e}} name="phone" placeholder="Enter phone number" value={user.phone.number}/>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                {ShowAdress()}
            </fieldset>
            <div id="BtnSaveInfoPerso">
                <Button style={{backGroundColor:'lightgreen',fontSize:'medium'}} onSubmit={e=>{SaveUser(user)}} loading={loading}  text={"Enregistrer"} />
            </div>
        </div>
        );
}